import transcosmosLogoType from './assets/logo_transcosmos.svg'

import './Onboarding.scss'

const TranscosmosLogotype = (): JSX.Element => (
  <img
    className="transcosmos-logotype"
    src={transcosmosLogoType}
    alt="Transcosmos logo"
  />
)

export default TranscosmosLogotype
