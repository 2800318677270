import React from 'react'
import { Switch, Route, useParams } from 'react-router-dom'

import Login from './Login'
import Signup from './Signup'
import ForgotPassword from './ForgotPassword'
import RecoverPassword from './RecoverPassword'
import Terms from './Terms'
import LanguagePath from './LanguagePath'
import UserEmailValidation from '../Onboarding/UserEmailValidation'

import { Logout } from './Logout'

const AuthRoutes = () => {
  const { lang } = useParams()

  return (
    <LanguagePath lang={lang}>
      <Switch>
        <Route exact path={`/${lang}/login`} component={Login} />
        <Route
          path={[`/${lang}/signup`, `/${lang}/accept-invite`]}
          render={props => (
            <Signup {...props}>
              <Switch>
                <Route
                  exact
                  path={[
                    `/${lang}/signup/terms`,
                    `/${lang}/accept-invite/terms`,
                  ]}
                  component={Terms}
                />
              </Switch>
            </Signup>
          )}
        />
        <Route
          exact
          path={`/${lang}/forgot-password`}
          component={ForgotPassword}
        />
        <Route
          exact
          path={`/${lang}/recover-password`}
          component={RecoverPassword}
        />
        <Route exact path={`/${lang}/logout`} component={Logout} />

        <Route
          exact
          path={`/${lang}/email-validation`}
          component={UserEmailValidation}
        />
      </Switch>
    </LanguagePath>
  )
}

export default AuthRoutes
