import React from 'react'
import classNames from 'classnames'

import Accordion from 'components/Shared/Accordion'
import ToggleField from 'components/Shared/Forms/ToggleField'
import { LAZY_LOADED_LISTS } from '../../../constants'

import './AdvancedListOptions.scss'
import SingleNumberControl from './SingleNumberControl'
import GenericInspectRow from './GenericRow'

const Children = props => {
  const { onChange, value, isCustomList, componentName, isResponsiveApp } =
    props

  const {
    listenForChanges = false,
    paginate = false,
    manualPagination = false,
    pageSize = 20,
  } = value?.options || {}

  const handleListenChange = listenForChanges => {
    onChange({ listenForChanges })
  }

  const handlePaginateChange = paginate => {
    onChange({
      paginate,
      manualPagination: false,
      pageSize: 20,
    })
  }

  const handleManualPaginateChange = manualPagination => {
    onChange({
      manualPagination,
      paginate: false,
    })
  }

  const handleChangePageSize = ({ pageSize }) => {
    const pageSizeInt = parseInt(pageSize, 10)

    onChange({ pageSize: pageSizeInt })
  }

  const showPaginationOption =
    isCustomList || LAZY_LOADED_LISTS.includes(componentName)
  const showManualPaginationOption = isCustomList && isResponsiveApp

  return (
    <>
      <ToggleField
        label="Auto refresh list when users add new items?*"
        helpText="*Too many lists with this enabled can slow down your app."
        onChange={handleListenChange}
        value={listenForChanges}
        inputValue="Auto Refresh"
        boxed
      />
      {showPaginationOption && (
        <ToggleField
          label="Only load & display visible list items?"
          inputValue="Load Items as User Scrolls"
          onChange={handlePaginateChange}
          value={paginate}
          boxed
        />
      )}
      {showManualPaginationOption && (
        <>
          <ToggleField
            label="Show paginated list items and controls?"
            inputValue="Manual Pagination"
            onChange={handleManualPaginateChange}
            value={manualPagination}
            boxed
          />
          <GenericInspectRow title="Page Size">
            <SingleNumberControl
              gray
              name="pageSize"
              value={pageSize}
              onChange={handleChangePageSize}
              displayName="Page Size"
              placeholder={20}
              disabled={!manualPagination}
              defaultValue={20}
              maxValue={20}
              minValue={1}
              boxed
            />
          </GenericInspectRow>
        </>
      )}
    </>
  )
}

const AdvancedListOptions = props => {
  const renderChildren = () => <Children {...props} />

  return (
    <Accordion
      className={classNames('advanced-options')}
      renderHeader={() => 'Advanced Options'}
      renderChildren={renderChildren}
    />
  )
}

AdvancedListOptions.propTypes = {}
AdvancedListOptions.defaultProps = {}

export default AdvancedListOptions
