import moment from 'moment'
import { isObject } from 'lodash'
import { buildIndex } from '@adalo/utils'
import { getApp } from 'ducks/apps'
import { getCurrentUser } from 'ducks/users'
import { getCurrentAppId } from 'ducks/editor/objects'

import { adaloBackendAxios } from 'utils/io/http/axios'
import { getOrgInitials } from 'utils/titles'
import { BEFORE, DURING, AFTER } from '../../constants'

const FETCH_ORGANIZATIONS = 'FETCH_ORGANIZATIONS'
const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION'
const UPDATE_ORGANIZATION_NAME = 'UPDATE_ORGANIZATION_NAME'
const UPDATE_ORGANIZATION_SUBDOMAIN = 'UPDATE_ORGANIZATION_SUBDOMAIN'
const UPDATE_ORGANIZATION_SEEN_END_TRIAL = 'UPDATE_ORGANIZATION_SEEN_END_TRIAL'
const UPDATE_ORGANIZATION_BILLING = 'UPDATE_ORGANIZATION_BILLING'
const CREATE_INVITE = 'CREATE_INVITE'
const DELETE_INVITE = 'DELETE_INVITE'
const REMOVE_USER = 'REMOVE_USER'
const BULK_REMOVE_USERS = 'BULK_REMOVE_USERS'
const START_TRIAL_FULFILLED = 'START_TRIAL_FULFILLED'
const SET_TRIAL_AS_OVER = 'SET_TRIAL_AS_OVER'
export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION'
export const FETCH_APP_ACTIONS_BY_CYCLE = 'FETCH_APP_ACTIONS_BY_CYCLE'
export const FETCH_CYCLE_APP_ACTIONS_BY_APP = 'FETCH_CYCLE_APP_ACTIONS_BY_APP'

export const INITIAL_STATE = {
  organizations: {},
  currentOrganization: null,
  currentOrganizationId: null,
}

// Reducer
function setTrialState(state, canStartTrial, isTrialActive, trialEndTimestamp) {
  const id = state.currentOrganizationId
  const organizations = { ...state.organizations }
  let currentOrganization = { ...state.currentOrganization }

  const newTrialState = {
    canStartTrial,
    isTrialActive,
    trialEndTimestamp,
  }

  if (organizations[id].trialState) {
    currentOrganization = {
      ...currentOrganization.trialState,
      ...newTrialState,
    }

    organizations[id].trialState = {
      ...organizations[id].trialState,
      ...newTrialState,
    }

    return {
      ...state,
      organizations,
      currentOrganization,
    }
  }

  return state
}

export default (state = INITIAL_STATE, action) => {
  if (action?.type === START_TRIAL_FULFILLED) {
    const { trialEndTimestamp } = action?.payload?.data

    return setTrialState(state, false, true, trialEndTimestamp)
  }

  if (action?.type === SET_TRIAL_AS_OVER) {
    return setTrialState(state, false, false)
  }

  if (action?.type === `${FETCH_ORGANIZATIONS}_FULFILLED`) {
    const organizations = buildIndex(action.payload.data, org => {
      return org.id
    })

    return {
      ...state,
      organizations,
    }
  }

  if (action?.type === `${UPDATE_ORGANIZATION_NAME}_FULFILLED`) {
    const organization = action.payload.data

    return {
      ...state,
      organizations: {
        ...state.organizations,
        [organization.id]: {
          ...state.organizations[organization.id],
          ...organization,
        },
      },
    }
  }
  if (action?.type === `${UPDATE_ORGANIZATION_SUBDOMAIN}_FULFILLED`) {
    const organization = action.payload.data

    return {
      ...state,
      organizations: {
        ...state.organizations,
        [organization.id]: {
          ...state.organizations[organization.id],
          ...organization,
        },
      },
    }
  }

  if (action?.type === `${UPDATE_ORGANIZATION_SEEN_END_TRIAL}_FULFILLED`) {
    const organization = action.payload.data

    return {
      ...state,
      organizations: {
        ...state.organizations,
        [organization.id]: {
          ...state.organizations[organization.id],
          ...organization,
        },
      },
    }
  }

  if (action?.type === `${UPDATE_ORGANIZATION_BILLING}_FULFILLED`) {
    const orgBilling = action.payload.data

    return {
      ...state,
      organizations: {
        ...state.organizations,
        [orgBilling.OrganizationId]: {
          ...state.organizations[orgBilling.OrganizationId],
          billing: orgBilling,
        },
      },
    }
  }

  if (action?.type === `${SET_CURRENT_ORGANIZATION}`) {
    const { payload: organizationId } = action

    return {
      ...state,
      currentOrganizationId: organizationId,
    }
  }

  if (action?.type === `${FETCH_ORGANIZATION}_FULFILLED`) {
    const { payload } = action
    const id = payload.data.id
    const organization = payload.data
    const organizations = { ...state.organizations }

    organizations[id] = {
      ...state.organizations[id],
      ...organization,
    }

    return {
      ...state,
      organizations,
      currentOrganization: organization,
    }
  }

  if (action?.type === `${FETCH_APP_ACTIONS_BY_CYCLE}_FULFILLED`) {
    const {
      payload: { data: cycles },
    } = action

    return {
      ...state,
      cycles,
    }
  }

  if (action?.type === `${FETCH_CYCLE_APP_ACTIONS_BY_APP}_FULFILLED`) {
    const {
      payload: { data: appActions },
    } = action

    return { ...state, appActions }
  }

  if (action?.type === 'UPDATE_ORGANIZATION_STATE_BY_ID') {
    const { payload } = action
    const { data, orgId } = payload

    let newState = {
      ...state,
      currentOrganization: {
        ...state.currentOrganization,
        ...data,
      },
    }

    if (state.organizations[orgId]) {
      newState = {
        ...newState,
        organizations: {
          ...state.organizations,
          [orgId]: {
            ...state.organizations[orgId],
            ...data,
          },
        },
      }
    }

    return newState
  }

  return state
}

// Actions
export const setTrialAsOver = () => ({
  type: SET_TRIAL_AS_OVER,
})

export const fetchOrganizations = () => ({
  type: FETCH_ORGANIZATIONS,
  payload: adaloBackendAxios.get(`/organizations`),
})

export const fetchOrganization = id => ({
  type: FETCH_ORGANIZATION,
  payload: adaloBackendAxios.get(`/organizations/${id}`),
})

export const updateOrganizationName = (id, name) => ({
  type: UPDATE_ORGANIZATION_NAME,
  payload: adaloBackendAxios.put(`/organizations/${id}/name`, { name }),
})

export const updateOrganizationSubdomain = (id, subdomain) => ({
  type: UPDATE_ORGANIZATION_SUBDOMAIN,
  payload: adaloBackendAxios.put(`/organizations/${id}/subdomain`, {
    subdomain,
  }),
})

export const updateOrganizationSeenEndTrial = (
  id,
  seenEndIntegrationTrial
) => ({
  type: UPDATE_ORGANIZATION_SUBDOMAIN,
  payload: adaloBackendAxios.put(`/organizations/${id}/seen-end-trial`, {
    seenEndIntegrationTrial,
  }),
})

export const updateOrganizationBilling = (id, organization) => ({
  type: UPDATE_ORGANIZATION_BILLING,
  payload: adaloBackendAxios.put(`/organizations/${id}/billing`, organization),
})

export const createInvite = ({ email, organizationId, appId }) => ({
  type: CREATE_INVITE,
  payload: adaloBackendAxios.post(`/invitations`, {
    email,
    organizationId,
    appId,
  }),
})

export const removeUser = ({ organizationId, userId }) => ({
  type: REMOVE_USER,
  payload: adaloBackendAxios.delete(
    `/organizations/${organizationId}/users/${userId}`
  ),
})

export const bulkRemoveUsers = (organizationId, userIds) => ({
  type: BULK_REMOVE_USERS,
  payload: adaloBackendAxios.delete(`/organizations/${organizationId}/users`, {
    data: { userIds },
  }),
})

export const deleteInvite = inviteId => ({
  type: DELETE_INVITE,
  payload: adaloBackendAxios.delete(`/invitations/${inviteId}`),
})

export const setCurrentOrganization = organizationId => ({
  type: 'SET_CURRENT_ORGANIZATION',
  payload: organizationId,
})

export const setDefaultCurrentOrganization = organizationId => {
  return (dispatch, getState) => {
    const { currentOrganizationId } = getState()

    if (!currentOrganizationId) {
      dispatch(setCurrentOrganization(organizationId))
    }
  }
}

export const getOrganizationLastApp = async (organizationId, userId) => {
  try {
    const response = await adaloBackendAxios.get(
      `/organizations/${organizationId}/users/${userId}/last-app`
    )

    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getOrganizationBilling = async id => {
  try {
    const { data } = await adaloBackendAxios.get(`/organizations/${id}/billing`)

    return data
  } catch (e) {
    return null
  }
}

export const updateOrganizationByID = (orgId, data) => ({
  type: 'UPDATE_ORGANIZATION_STATE_BY_ID',
  payload: { orgId, data },
})

// Selectors

/**
 * @typedef {import('./Organization').Organization} Organization
 */

/**
 * @param {object} state
 * @returns {Array<Organization>}
 */
export const getOrganizations = state => {
  const organizations = Object.values(state.organizations.organizations)

  if (organizations) {
    return organizations.slice().sort((a, b) => a.name.localeCompare(b.name))
  }

  return []
}

export const getOrganization = (state, orgId) => {
  return state.organizations.organizations[orgId]
}

/**
 * @typedef {import('./Organization').Organization} Organization
 */

/**
 * @param {object} state
 * @returns {Organization}
 */
export const getCurrentOrganization = state => {
  const currentUser = getCurrentUser(state)
  const { admin } = currentUser || {}

  const currentOrganization = state.organizations?.currentOrganization

  if (admin) {
    return currentOrganization
  }

  const organization =
    state?.organizations?.organizations?.[
      state.organizations?.currentOrganizationId
    ]

  if (
    isObject(organization) &&
    isObject(currentOrganization) &&
    organization.id === currentOrganization.id
  ) {
    return {
      ...currentOrganization,
      ...organization,
    }
  }

  return organization || currentOrganization
}

// Intentionally not exported
// Intentionally awkward function name
const getCurrentOrganizationViaOrganizationStateOrAppState = state => {
  const currentOrg = getCurrentOrganization(state)

  if (currentOrg) {
    return currentOrg
  }

  const appId = getCurrentAppId(state)
  const app = getApp(state, appId)

  return app?.Organization
}

export const getTrialRemainingTime = state => {
  const organization = getCurrentOrganization(state)
  const trialState = organization?.trialState

  if (!trialState) {
    return 0
  }

  const { trialEndTimestamp } = trialState

  const end = moment(trialEndTimestamp)
  const now = moment()
  const remainingTime = end.diff(now).valueOf()

  return remainingTime
}

export const getTrialState = state => {
  const organization =
    getCurrentOrganizationViaOrganizationStateOrAppState(state)

  const trialState = organization?.trialState

  if (!trialState) {
    return {}
  }

  let remainingDays = null

  const { canStartTrial, isTrialActive, trialEndTimestamp } =
    organization.trialState

  let trialStateString

  if (canStartTrial) {
    trialStateString = BEFORE
  } else if (isTrialActive) {
    trialStateString = DURING
    const now = moment()
    const end = moment(trialEndTimestamp)
    const difference = end.diff(now)
    remainingDays = moment.duration(difference).asDays().toFixed()
  } else {
    trialStateString = AFTER
  }

  return {
    trialState: trialStateString,
    days: remainingDays,
    trialEndTimestamp,
    canExtendTrial: trialState.canExtendTrial,
  }
}

export const getPlanFeatures = state => {
  const organization =
    getCurrentOrganizationViaOrganizationStateOrAppState(state)
  const planFeatures = organization?.planFeatures

  return planFeatures || []
}

export const isFeatureEnabled = (state, feature) => {
  const organization =
    getCurrentOrganizationViaOrganizationStateOrAppState(state)
  const enabledFeatures = organization?.enabledFeatures

  return enabledFeatures?.includes(feature)
}

export const getActiveState = state => {
  const organization =
    getCurrentOrganizationViaOrganizationStateOrAppState(state)
  const active = organization?.active

  return active
}

export const getCurrentOrganizationId = state => {
  return state.organizations?.currentOrganizationId
}

export const getCurrentOrgInitialDetails = state => {
  const colors = ['teal', 'orange', 'yellow']
  const currentOrganization = getCurrentOrganization(state)
  const organizations = getOrganizations(state)

  let orgInitials = 'A'
  let orgIndex = 0

  if (currentOrganization) {
    if (currentOrganization.name) {
      orgInitials = getOrgInitials(currentOrganization.name)
    }

    if (organizations) {
      orgIndex = organizations.findIndex(
        org => org.id === currentOrganization.id
      )
    }
  }

  const color = colors[orgIndex % colors.length]

  return { orgInitials, orgColor: color }
}
