import React, { useEffect } from 'react'
import { getDatasources } from 'ducks/apps/datasources'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { getParams } from 'ducks/apps/params'
import { getApp, updateApp } from 'ducks/apps'
import SettingsFormField, {
  SettingsFormSubmit,
} from 'components/Shared/Forms/SettingsFormField'
import { Field, initialize, reduxForm } from 'redux-form'

import './ScreenDeepLinking.scss'

const FORM_NAME = 'screenDeepLinkingForm'

const validate = values => {
  const errors = {}

  const cleanedAlias = values.alias?.startsWith('/')
    ? values.alias.slice(1)
    : values.alias || ''

  const regex = /^[a-zA-Z0-9]+$/

  if (!regex.test(cleanedAlias) && cleanedAlias.length > 0) {
    errors.alias = 'Invalid screen route name'
  } else if (cleanedAlias.length > 50) {
    errors.alias = 'Screen route name must be less than 50 characters'
  }

  return errors
}

const ScreenDeepLinking = ({
  component,
  app,
  handleSubmit,
  submitting,
  submitSucceeded,
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (!app?.deepLinking) return

    const aliases = app.deepLinking.aliases || {}
    const aliasKey =
      Object.keys(aliases).find(
        key => aliases[key].componentId === component.id
      ) || ''

    const initialValues = { alias: aliasKey }
    dispatch(initialize(FORM_NAME, initialValues))
  }, [app, component.id, initialize])

  const onSubmit = async values => {
    if (!app) return
    await new Promise(resolve => setTimeout(resolve, 200))

    const { alias } = values
    const cleanedAlias = alias ? alias.replace(/^\/+/, '') : ''

    const { deepLinking = {} } = app
    const { aliases = {} } = deepLinking

    // Remove existing entries for this component
    const newAliases = Object.entries(aliases).reduce((acc, [key, value]) => {
      if (value.componentId !== component.id) {
        acc[key] = value
      }

      return acc
    }, {})

    // Add new alias if not empty
    if (cleanedAlias) {
      newAliases[cleanedAlias] = { componentId: component.id }
    }

    await dispatch(
      updateApp(app.id, {
        deepLinking: {
          ...deepLinking,
          aliases: newAliases,
        },
      })
    )
  }

  return (
    <form className="params-section" onSubmit={handleSubmit(onSubmit)}>
      <div className="screen-deep-linking">
        <Field
          component={SettingsFormField}
          name="alias"
          label="Screen Route Name"
          placeholder="Enter a screen route name"
          type="text"
          // onChange={updateAlias}
          parse={value => (value?.startsWith('/') ? value : `/${value}`)}
          format={value => (value?.startsWith('/') ? value : `/${value}`)}
          tooltip="This is the name of the route that will be used to navigate to this screen, e.g. /profile, /settings"
        />
        <SettingsFormSubmit
          className="screen-route-submit"
          enabled={!submitting}
          title={submitting ? 'Saving...' : 'Save Route'}
        />
      </div>
    </form>
  )
}

const mapStateToProps = (state, { match, component }) => ({
  datasources: getDatasources(state, match.params.appId),
  params: getParams(state, match.params.appId, component.id),
  app: getApp(state, match.params.appId),
})

export default withRouter(
  connect(mapStateToProps)(
    reduxForm({
      form: FORM_NAME,
      enableReinitialize: false,
      touchOnChange: true,
      touchOnBlur: true,
      initialValues: {
        alias: '',
      },
      validate,
      onSubmit: (values, dispatch, props) => {
        const { onSubmit } = props
        if (onSubmit) {
          onSubmit(values)
        }
      },
    })(ScreenDeepLinking)
  )
)
