import { validateEmailForm } from 'components/Auth/utils'
import Button from 'components/Shared/Button'
import { FormRow } from 'components/Shared/Forms/FormRow'
import WrappedInput from 'components/Shared/Forms/WrappedInput'
import {
  CurrentUser,
  getCurrentUser,
  UserState,
  validateUserEmail,
} from 'ducks/users'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  DecoratedFormProps,
  Field,
  FormErrors,
  FormSubmitHandler,
  InjectedFormProps,
  reduxForm,
} from 'redux-form'
import Loading from 'components/Shared/Loading'
import QS from 'qs'
import { connect, DefaultRootState, useDispatch } from 'react-redux'
import { FormEventHandler, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import AdaloLogotype from './AdaloLogotype'
import OnboardingLayout from './Layout'
import TranscosmosLogotype from './TranscosmosLogotype'

const FORM_NAME = 'emailValidationForm'

type ValidationFormValues = {
  emailValidation: string
}

type Props = {
  currentUser: CurrentUser
  handleSubmit: (
    submit: FormSubmitHandler<ValidationFormValues, Props, string>,
    props?: DecoratedFormProps<ValidationFormValues, Props, string> | undefined,
    valid?: boolean | undefined,
    asyncValidate?: unknown,
    fields?: string[] | undefined
  ) => FormEventHandler<HTMLFormElement> | undefined
}

const UserEmailValidation = ({
  submitFailed,
  error,
  submitting,
  currentUser,
  handleSubmit,
}: Props & InjectedFormProps<ValidationFormValues, Props>) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const isJapanese = useMemo(() => i18n.language === 'ja', [i18n.language])
  const queryParams = QS.parse(location.search, { ignoreQueryPrefix: true })
  const { redirect, token } = queryParams
  const redirectQuery = QS.stringify({ redirect }, { addQueryPrefix: true })

  if (!currentUser) {
    return (
      <OnboardingLayout>
        <Loading expanded />
      </OnboardingLayout>
    )
  }

  const navigateToOnboarding = () => {
    if (token) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      history.push(`/onboarding?token=${token}`)
    } else {
      history.push('/onboarding')
    }
  }

  const submitFunction = (values: ValidationFormValues) => {
    const code = values.emailValidation

    dispatch(
      validateUserEmail({
        code,
        email: currentUser?.email,
        callback: navigateToOnboarding,
      })
    )
  }

  return (
    <OnboardingLayout>
      <form
        onSubmit={handleSubmit(submitFunction)}
        className={classNames('auth-form-v2', {
          'auth-form-v2--transcosmos': isJapanese,
        })}
      >
        <div
          className={classNames('auth-form-v2__header', {
            'auth-form-v2__header--transcosmos': isJapanese,
          })}
        >
          <div className="auth-form-v2__header-logos">
            <AdaloLogotype />
            {isJapanese && <TranscosmosLogotype />}
          </div>
          <h1>{t('Validate Your Email')}</h1>
        </div>
        <p>{t('Please enter the validation code sent to your email')}</p>
        <div className="auth-form-v2__content">
          <FormRow className="email-validation-form-row">
            <Field
              autoFocus
              name="emailValidation"
              placeholder="123456"
              type="string"
              label={t('Validation Code')}
              component={WrappedInput}
              autoComplete="string"
              maxLength={6}
            />
          </FormRow>
          <div className="auth-form-submission-row">
            <Button
              square
              gradient
              large
              teal={!isJapanese}
              transcosmosRed={isJapanese}
              fluid
              loading={submitting}
            >
              {t("Let's do this!")}
            </Button>
          </div>
          {error && submitFailed && (
            <div className="auth-form-error">{error}</div>
          )}
        </div>
        <div className="auth-form-v2__footer">
          <div
            className={classNames('auth-form-link-v2', {
              'auth-form-link-v2--transcosmos': isJapanese,
            })}
          >
            {`${t('Already have an account?')} `}
            <Link to={`/login${redirectQuery}`} className="link-button">
              {t('Login')}
            </Link>
          </div>
        </div>
      </form>
    </OnboardingLayout>
  )
}

const validate = validateEmailForm as (
  values: ValidationFormValues,
  props: DecoratedFormProps<ValidationFormValues, Props, string>
) => FormErrors<ValidationFormValues, string>

const WrappedEmailValidation = reduxForm<ValidationFormValues, Props>({
  form: FORM_NAME,
  validate,
  destroyOnUnmount: false,
})(UserEmailValidation)

const mapStateToProps = (state: DefaultRootState) => {
  const currentUser = getCurrentUser(state as UserState)

  return {
    currentUser,
  }
}

export default connect(mapStateToProps, { validateUserEmail })(
  WrappedEmailValidation
)
