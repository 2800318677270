import React from 'react'
import classNames from 'classnames'
import { borderPosition, borderStyle } from '@adalo/constants'

import BaseShape from '../BaseShape'
import Shadow from '../Shadow'
import GroupWrapper from '../Group/GroupWrapper'
import Rect from './Rect'

export default class Section extends BaseShape {
  getRenderOffset() {
    if (this.props.borderStyle !== borderStyle.NONE) {
      switch (this.props.borderPosition) {
        case borderPosition.INSIDE:
          return 0.5 * this.getBorderWidth()
        case borderPosition.OUTSIDE:
          return -0.5 * this.getBorderWidth()
        default:
      }
    }

    return 0
  }

  getOpacity = () => {
    const {
      object: { opacity },
    } = this.props

    return opacity || opacity === 0 ? { opacity } : null
  }

  getAttributeStyles() {
    const { opacity } = this.props

    return { opacity }
  }

  render() {
    let {
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      selected,
      borderRadius,
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomRightRadius,
      borderBottomLeftRadius,
      shadow,
      opacity,
      zoom,
      width,
      height,
      hideShadows,
      object,
      children,
      editorResizingProps,
      left,
      right,
      minWidth,
      maxWidth,
      ...styles
    } = this.props

    const borderProps = this.getBorderProps()
    const backgroundColor = this.getBackgroundColor()
    const renderOffset = this.getRenderOffset()

    xScaled += renderOffset
    yScaled += renderOffset
    widthScaled = editorResizingProps?.relativeWidth
      ? widthScaled
      : widthScaled - 2 * renderOffset
    heightScaled -= 2 * renderOffset

    return (
      <GroupWrapper object={object} editorResizingProps={editorResizingProps}>
        <g className="group" style={{ opacity }}>
          <g
            className={classNames('section', { 'section-selected': selected })}
            onMouseDown={this.handleMouseDown}
            onDoubleClick={this.handleDoubleClick}
            transform={`translate(${xScaled}, ${yScaled})`}
            style={styles}
          >
            {shadow && shadow.enabled && !hideShadows ? (
              <Shadow {...this.getShadowProps()}>
                <Rect
                  {...borderProps}
                  width={width}
                  height={height}
                  x={0}
                  y={0}
                  zoom={zoom}
                  widthScaled={widthScaled}
                  heightScaled={heightScaled}
                  borderRadius={borderRadius}
                  borderTopLeftRadius={borderTopLeftRadius}
                  borderTopRightRadius={borderTopRightRadius}
                  borderBottomRightRadius={borderBottomRightRadius}
                  borderBottomLeftRadius={borderBottomLeftRadius}
                  fill={backgroundColor}
                />
              </Shadow>
            ) : null}
            <Rect
              width={width}
              height={height}
              x={0}
              y={0}
              zoom={zoom}
              widthScaled={widthScaled}
              heightScaled={heightScaled}
              borderRadius={borderRadius}
              borderTopLeftRadius={borderTopLeftRadius}
              borderTopRightRadius={borderTopRightRadius}
              borderBottomRightRadius={borderBottomRightRadius}
              borderBottomLeftRadius={borderBottomLeftRadius}
              fill={backgroundColor}
              {...borderProps}
            />
          </g>
          {children}
        </g>
      </GroupWrapper>
    )
  }
}
