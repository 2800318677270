import { Dispatch } from 'react'

import { adaloBackendAxios, isAxiosError } from 'utils/io/http/axios'

import { RESET } from '..'

export const FETCH_USER = 'FETCH_USER'

type UserResponse = {
  data: {
    table: string
    fields: {
      [key: string]: {
        type: string
        required: boolean
        locked: boolean
        name: string
      }
    }
    '/users/{id}'?: ['post' | 'get' | 'delete']
    '/users'?: ['post' | 'get']
  }
}

type UserDispatch = {
  type: string
  payload: UserResponse
}

// Actions
export const validateUser =
  (url: string, token: string, appId: string) =>
  async (dispatch: Dispatch<UserDispatch>): Promise<void> => {
    try {
      const queryToken = token ? `&token=${token}` : ''
      const result = await adaloBackendAxios.get<UserResponse>(
        `/external-databases/xano/validate/user-collection?url=${url}&appId=${appId}${queryToken}`
      )

      return dispatch({
        type: FETCH_USER,
        payload: result.data,
      })
    } catch (error) {
      if (isAxiosError(error)) {
        return dispatch({
          type: FETCH_USER,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          payload: error.response?.data,
        })
      }
    }
  }

export const resetUser =
  () =>
  (dispatch: Dispatch<Omit<UserDispatch, 'payload'>>): void => {
    return dispatch({
      type: RESET,
    })
  }
