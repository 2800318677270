import moment from 'moment'

import {
  PLAN_FREE,
  PLAN_ERA_NONE,
  PLAN_ERA_2019_LAUNCH,
  PLAN_ERA_2021_CLEANUP,
  PLAN_ERA_2022,
  BUSINESS_ACTIONS_MULTIPLIER,
  BUSINESS_PRICE_MULTIPLIER,
  OTHER_TEAMS_ACTIONS_MULTIPLIER,
  OTHER_TEAMS_PRICE_MULTIPLIER,
  type PlanType,
  STARTER_PROFESSIONAL_2024_PRICE_MULTIPLIER,
  TEAM_2024_PRICE_MULTIPLIER,
  BUSINESS_2024_PRICE_MULTIPLIER,
  STARTER_PROFESSIONAL_2024_ACTIONS_MULTIPLIER,
  TEAM_2024_ACTIONS_MULTIPLIER,
  BUSINESS_2024_ACTIONS_MULTIPLIER,
} from '@adalo/constants'

import { adaloBackendAxios } from './io/http/axios'

export {
  PLAN_FREE,
  PLAN_ERA_NONE,
  PLAN_ERA_2019_LAUNCH,
  PLAN_ERA_2021_CLEANUP,
  PLAN_ERA_2022,
} from '@adalo/constants'

export const PRICE_TYPE_MONTHLY = 'monthly'
export const PRICE_TYPE_YEARLY = 'yearly'

export const ACTION_UPGRADE = 'UPGRADE'
export const ACTION_DOWNGRADE = 'DOWNGRADE'
export const ACTION_SWITCH_TO_MONTHLY = 'SWITCH_TO_MONTHLY'
export const ACTION_SWITCH_TO_YEARLY = 'SWITCH_TO_YEARLY'
export const ACTION_NONE = 'NONE'

// This plan ranking orders the plans to establish which transitions count as upgrades and which count as downgrades.
// The list is ordered from lowest plan to highest plan. Moving from a lower index to a higher index is considered an upgrade.
// An array of plans shows that they are of equal ranking, moving between them does not represent an upgrade or downgrade
const PLAN_RANKING: (PlanType | PlanType[])[] = [
  'free',
  [
    'starter', // Legacy
    'starter2023',
    'starter2024',
  ],
  'pro', // Legacy
  ['professional', 'professional2023', 'professional2024'],
  ['team', 'team2024'],
  'business', // Legacy
  ['business2022', 'business2023', 'business2024'],
]

// Describe the current state.
// Move old CURRENT_ERAS into PAST_ERAS when a new current era is introduced.
const CURRENT_ERA = PLAN_ERA_2022
const PAST_ERAS = [PLAN_ERA_2019_LAUNCH, PLAN_ERA_2021_CLEANUP]

type PlanEraTypes =
  | typeof PLAN_ERA_NONE
  | typeof PLAN_ERA_2019_LAUNCH
  | typeof PLAN_ERA_2021_CLEANUP
  | typeof PLAN_ERA_2022

/**
 * We group plans by "Era" rather than referring to "New" and "Old" plans.
 * A plan is only "New" until newer plans come along.
 */
const PLAN_ERAS: Record<PlanEraTypes, Array<PlanType>> = {
  [PLAN_ERA_NONE]: ['free'],
  [PLAN_ERA_2019_LAUNCH]: ['launch', 'individual'], // Defunct - no longer used but still found in code
  [PLAN_ERA_2021_CLEANUP]: ['pro', 'business'],
  [PLAN_ERA_2022]: [
    'starter',
    'starter2023',
    'starter2024',
    'professional',
    'professional2023',
    'professional2024',
    'team',
    'team2024',
    'business2022',
    'business2023',
    'business2024',
  ],
}

// TODO(daniel): Improve this typing and move to constants/backend
type PlanAttributes = {
  perks: string[]
  prices: {
    monthly: number
    yearly: number
  }
  planDisplayName: string
  limits: {
    actions: number
    testApps: number
    publishedApps: number
    seats: number
  }
}

type PricingPlans = Record<PlanType, PlanAttributes>

export const getPriceMultiplier = (planType: string): number => {
  switch (planType) {
    case 'starter2024':
    case 'professional2024':
      return STARTER_PROFESSIONAL_2024_PRICE_MULTIPLIER
    case 'team2024':
      return TEAM_2024_PRICE_MULTIPLIER
    case 'business2024':
      return BUSINESS_2024_PRICE_MULTIPLIER
    case 'business2022':
    case 'business2023':
      return BUSINESS_PRICE_MULTIPLIER
    default:
      return OTHER_TEAMS_PRICE_MULTIPLIER
  }
}

export const getActionsMultiplier = (planType: string): number => {
  switch (planType) {
    case 'starter2024':
    case 'professional2024':
      return STARTER_PROFESSIONAL_2024_ACTIONS_MULTIPLIER
    case 'team2024':
      return TEAM_2024_ACTIONS_MULTIPLIER
    case 'business2024':
      return BUSINESS_2024_ACTIONS_MULTIPLIER
    case 'business2022':
    case 'business2023':
      return BUSINESS_ACTIONS_MULTIPLIER
    default:
      return OTHER_TEAMS_ACTIONS_MULTIPLIER
  }
}

export const getPlanEra = (planType: PlanType): string => {
  if (!planType) {
    return PLAN_ERA_NONE
  }

  for (const eraId in PLAN_ERAS) {
    if (PLAN_ERAS[eraId as PlanEraTypes]?.includes(planType)) {
      return eraId
    }
  }

  // Make sure new plan types are added to PLAN_ERAS
  throw new Error(`Unknown planType: ${planType}`)
}

export const normalizePlanType = (subscriptionPlanType: PlanType): PlanType => {
  if (!subscriptionPlanType) {
    return PLAN_FREE
  }

  return subscriptionPlanType
}

// Old starter is not part of PAST_ERAS since it currently still behaves as a CURRENT_ERA
// We don't want to refer to it as "legacy" in the UI at the moment
export const isLegacyPlanType = (planType: PlanType): boolean => {
  return PAST_ERAS.includes(getPlanEra(planType))
}

export const isCurrentPlanType = (planType: PlanType): boolean =>
  CURRENT_ERA === getPlanEra(planType)

export const isPaidPlan = (planType: PlanType): boolean =>
  normalizePlanType(planType) !== PLAN_FREE

export const getPlanName = (
  planType: PlanType,
  pricingPlans: PricingPlans
): string | undefined => {
  if (planType in pricingPlans) {
    return pricingPlans[planType]?.planDisplayName
  } else {
    return undefined
  }
}

const checkDateInMonth = (
  date: number,
  month: number,
  year: number
): number => {
  const daysInMonth = new Date(year, month + 1, 0).getDate()

  if (date > daysInMonth) {
    return daysInMonth
  }

  return date
}

export const getLimit = (
  planType: PlanType,
  pricingPlans: PricingPlans,
  limitType: 'actions' | 'testApps' | 'publishedApps' | 'seats'
): number | undefined => {
  if (planType in pricingPlans) {
    return pricingPlans[planType]?.limits[limitType]
  } else {
    return undefined
  }
}

export const getPlanPrice = (
  planType: PlanType,
  pricingPlans: PricingPlans,
  priceType: 'monthly' | 'yearly'
): number | undefined => {
  if (planType in pricingPlans) {
    return pricingPlans[planType]?.prices[priceType]
  } else {
    return undefined
  }
}

export const getPlanPerks = (
  planType: PlanType,
  pricingPlans: PricingPlans
): string[] | undefined => {
  if (planType in pricingPlans) {
    return pricingPlans[planType]?.perks
  } else {
    return undefined
  }
}

const normalizePlanInterval = (
  subscriptionPlanInterval: 'month' | 'year'
): 'monthly' | 'yearly' => {
  if (subscriptionPlanInterval === 'month') {
    return PRICE_TYPE_MONTHLY
  }

  if (subscriptionPlanInterval === 'year') {
    return PRICE_TYPE_YEARLY
  }

  return subscriptionPlanInterval
}

// TODO(daniel): Add proper types to subscription
export const normalizeSubscription = (subscription: {
  planType: PlanType
  planInterval: 'month' | 'year'
}): {
  planType: PlanType
  planInterval: 'monthly' | 'yearly'
} => {
  const { planType, planInterval } = subscription || {}

  return {
    ...subscription,
    planType: normalizePlanType(planType),
    planInterval: normalizePlanInterval(planInterval),
  }
}

export const getPlanAction = (
  planRanks: Record<PlanType, number>,
  planType: PlanType,
  currentPlanType: PlanType,
  currentPlanInterval: 'monthly' | 'yearly',
  annualSelected: boolean
): string => {
  if (planType === PLAN_FREE) {
    if (!currentPlanType || currentPlanType === PLAN_FREE) {
      return ACTION_NONE
    }

    // Special case to always consider free a downgrade.
    return ACTION_DOWNGRADE
  }

  const targetPlanTypeRank = planRanks[planType]
  const currentPlanTypeRank = planRanks[currentPlanType]

  if (targetPlanTypeRank === currentPlanTypeRank) {
    if (currentPlanInterval === PRICE_TYPE_MONTHLY && annualSelected) {
      return ACTION_SWITCH_TO_YEARLY
    }

    if (currentPlanInterval === PRICE_TYPE_YEARLY && !annualSelected) {
      return ACTION_SWITCH_TO_MONTHLY
    }

    return ACTION_NONE
  }

  if (targetPlanTypeRank < currentPlanTypeRank) {
    return ACTION_DOWNGRADE
  }

  return ACTION_UPGRADE
}

export const getPlanActions = (
  currentPlanType: PlanType,
  currentPlanInterval: 'monthly' | 'yearly',
  annualSelected: boolean
): {
  [key: string]: string
} => {
  const planRanks = {} as Record<PlanType, number>

  for (let i = 0; i < PLAN_RANKING.length; i += 1) {
    const rankingPlan = PLAN_RANKING[i]
    if (Array.isArray(rankingPlan)) {
      rankingPlan.forEach(plan => {
        const planType: PlanType = plan || 'free'
        planRanks[planType] = i
      })
    } else {
      const planType: PlanType = rankingPlan || 'free'
      planRanks[planType] = i
    }
  }

  const result: { [key: string]: string } = {}

  for (const planType of PLAN_RANKING.flat()) {
    result[planType] = getPlanAction(
      planRanks,
      planType,
      currentPlanType,
      currentPlanInterval,
      annualSelected
    )
  }

  return result
}

// TODO(daniel): Add types for org
export const getDaysLeftInCycle = (org: {
  billingStartDate: Date
  createdAt: Date
}): number => {
  const billingCycleAnchor = org.billingStartDate
    ? new Date(org.billingStartDate)
    : new Date(org.createdAt)

  const billingCycleAnchorDate = billingCycleAnchor.getDate()

  const cycleEndDate = new Date()
  const today = new Date()

  if (today.getDate() >= billingCycleAnchorDate) {
    cycleEndDate.setDate(1)
    cycleEndDate.setMonth(cycleEndDate.getMonth() + 1)
  }

  cycleEndDate.setDate(
    checkDateInMonth(
      billingCycleAnchorDate,
      cycleEndDate.getMonth(),
      cycleEndDate.getFullYear()
    )
  )

  const difference = cycleEndDate.getTime() - today.getTime()

  return Math.ceil(difference / (1000 * 3600 * 24))
}

// TODO(daniel): Add types for org
export const getCycleStartAndEndDates = (org: {
  billingStartDate: Date
  createdAt: Date
}): {
  cycleStartDate: moment.Moment
  cycleEndDate: moment.Moment
} => {
  const billingCycleAnchor = org.billingStartDate
    ? moment(org.billingStartDate)
    : moment(org.createdAt)

  const billingCycleAnchorDate = billingCycleAnchor.date()

  let cycleStartDate = moment()

  if (cycleStartDate.date() < billingCycleAnchorDate) {
    if (cycleStartDate.month() > 0) {
      cycleStartDate.date(1)
      cycleStartDate.month(cycleStartDate.month() - 1)
    } else {
      cycleStartDate.date(1)
      cycleStartDate.month(11)
      cycleStartDate.year(cycleStartDate.year() - 1)
    }
  }

  cycleStartDate = moment()
    .year(cycleStartDate.year())
    .month(cycleStartDate.month())
    .date(
      checkDateInMonth(
        billingCycleAnchorDate,
        cycleStartDate.month(),
        cycleStartDate.year()
      )
    )

  let cycleEndDate = moment()

  if (billingCycleAnchorDate > 1) {
    cycleEndDate.date(
      checkDateInMonth(
        billingCycleAnchorDate - 1,
        cycleEndDate.month(),
        cycleEndDate.year()
      )
    )
  } else {
    if (cycleEndDate.month() > 0) {
      cycleEndDate.date(1)
      cycleEndDate.month(cycleEndDate.month() - 1)
    } else {
      cycleEndDate.date(1)
      cycleEndDate.month(11)
      cycleEndDate.year(cycleEndDate.year() - 1)
    }

    cycleEndDate.date(
      checkDateInMonth(
        billingCycleAnchorDate - 1,
        cycleEndDate.month(),
        cycleEndDate.year()
      )
    )
  }

  const today = moment()

  if (today.date() >= billingCycleAnchorDate) {
    if (cycleEndDate.month() < 11) {
      cycleEndDate.date(1)
      cycleEndDate.month(cycleEndDate.month() + 1)
    } else {
      cycleEndDate.date(1)
      cycleEndDate.month(0)
      cycleEndDate.year(cycleEndDate.year() + 1)
    }
  }

  cycleEndDate = moment()
    .year(cycleEndDate.year())
    .month(cycleEndDate.month())
    .date(
      checkDateInMonth(
        billingCycleAnchorDate - 1,
        cycleEndDate.month(),
        cycleEndDate.year()
      )
    )

  cycleEndDate = moment()
    .year(cycleEndDate.year())
    .month(cycleEndDate.month())
    .date(
      checkDateInMonth(
        billingCycleAnchorDate - 1,
        cycleEndDate.month(),
        cycleEndDate.year()
      )
    )

  return {
    cycleStartDate,
    cycleEndDate,
  }
}

// TODO(daniel): Add types to cycles
export const getCurrentCycle = (
  cycles: { startDate: Date; endDate: Date }[]
): { startDate: Date; endDate: Date } | void => {
  const today = moment().startOf('day')

  for (const cycle of cycles) {
    if (today.isBetween(cycle.startDate, cycle.endDate, 'day', '[]')) {
      return cycle
    }
  }
}

export const createStripeCustomerPortal = async (
  organizationId: string | number,
  appId: string
): Promise<void> => {
  try {
    const portal: { data: { url: string } } = await adaloBackendAxios.post(
      `/stripe/portal-url`,
      {
        organizationId,
        appId,
      }
    )

    window.open(portal.data.url, '_blank')
  } catch (err) {
    console.error('Problem creating Stripe Portal: ', err)
  }
}

type OptionsType = { value: number; label: string }[]

export const getSpendingLimitOptions = async (
  planType: string
): Promise<OptionsType> => {
  try {
    const { data: options }: { data: OptionsType } =
      await adaloBackendAxios.get(`/spending-limit/options`, {
        params: {
          planType,
        },
      })

    return options
  } catch (err) {
    console.error('FAILED TO FETCH SPENDING LIMIT OPTIONS: ', err)

    return []
  }
}

export const hasBusinessPlan = (planType: string): boolean =>
  ['business2022', 'business2023', 'business2024'].includes(planType)

export const getActionsSpendingLimit = (
  billingSpendingLimit: number | null | undefined,
  overageChargePlanType: PlanType
): number | null => {
  const unlimited = billingSpendingLimit === null
  if (unlimited) {
    return null
  }
  const spendingLimit = billingSpendingLimit ?? 0

  const priceMultiplier = getPriceMultiplier(overageChargePlanType)
  const actionsMultiplier = getActionsMultiplier(overageChargePlanType)

  return (spendingLimit / priceMultiplier) * actionsMultiplier
}

export const fetchPricingPlans = async (): Promise<PricingPlans> => {
  const { data: plans }: { data: PricingPlans } = await adaloBackendAxios.get(
    `/pricing-plans`
  )

  return plans
}

export const getTotalActions = (
  actionsByApp: [{ actionCount: number }]
): number => actionsByApp.reduce((sum, val) => sum + val.actionCount, 0)
