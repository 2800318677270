import React, { useMemo } from 'react'
import { Field, formValueSelector } from 'redux-form'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { GroupedAccordion } from 'components/Shared/Accordion'
import Icon from 'components/Shared/Icon'
import EmptyState from 'components/Shared/EmptyState'
import Loading from 'components/Shared/Loading'

import WrappedInput from 'components/Shared/Forms/WrappedInput'
import ToggleCheckbox from 'components/Shared/Forms/ToggleCheckbox'

import { EndpointTestContent } from './ExternalUsersForm'
import { FORM_NAME } from './index'

const ExternalCollectionsForm = props => {
  const { spec, status, datasource } = props
  const selector = formValueSelector(FORM_NAME)
  const apiURL = useSelector(state => selector(state, 'apiURL'))

  return (
    <>
      <div className="api-url-input">
        <Field
          id="xano-url-input"
          name="apiURL"
          label="Xano API Base URL"
          component={WrappedInput}
          type="url"
          spellCheck="false"
          suffix={<Icon color="teal" type="check-circle" />}
          formName={FORM_NAME}
          disabled
        />
      </div>
      <div className="api-url-input">
        <Field
          id="xano-metadata-token-input"
          name="maskedToken"
          label="Swagger API token (optional, only for Private Swagger documentation)"
          component={WrappedInput}
          spellCheck="false"
          formName={FORM_NAME}
          disabled
        />
      </div>
      <div name="endpoints" className="external-users-endpoints">
        <span className="active">
          Select Your Xano Endpoints for All Other Collections (optional)
        </span>

        <Endpoints
          datasource={datasource}
          url={apiURL}
          spec={spec}
          status={status}
        />
      </div>
    </>
  )
}

const EndpointsTitle = ({ title, selected }) => {
  return (
    <div>
      <h3>{title}</h3>
    </div>
  )
}

const EndpointsContent = ({ endpoints, url, selected }) => {
  return (
    <div className="endpoints-container">
      {endpoints.map(endpoint => {
        return (
          <EndpointTestContent key={endpoint.label} url={url} {...endpoint} />
        )
      })}
    </div>
  )
}

const Endpoints = ({ url, spec, status, datasource }) => {
  const selector = formValueSelector(FORM_NAME)
  const collections = useSelector(state => selector(state, 'collections'))

  const existingCollectionNames = useMemo(() => {
    if (datasource?.tables) {
      return Object.entries(datasource.tables).map(([key, value]) => value.name)
    }

    return []
  }, [JSON.stringify(datasource?.tables || {})])

  const filteredSpec = useMemo(() => {
    const value = { ...spec }

    for (const key of Object.keys(value)) {
      if (existingCollectionNames.includes(key)) delete value[key]
    }

    return value
  }, [JSON.stringify(spec || {}), existingCollectionNames.length])

  if (status === 'loading') {
    return (
      <EmptyState greedy>
        <Loading />
      </EmptyState>
    )
  }

  if (status === 'error') {
    return <EmptyState greedy>Error</EmptyState>
  }

  if (Object.keys(filteredSpec).length === 0) {
    return <EmptyState greedy>No Collections</EmptyState>
  }

  return Object.entries(filteredSpec).map(([title, endpoints]) => {
    const selected = collections?.[title]

    return (
      <div className="external-users-endpoints-item" key={title}>
        <Field component={ToggleCheckbox} name={`collections.${title}`} />
        <GroupedAccordion
          key={title}
          group={`external-collection-endpoints-${title}`}
          title={<EndpointsTitle title={title} selected={selected} />}
          className={classNames('active', 'select', { selected })}
          renderChildren={() => (
            <EndpointsContent
              endpoints={endpoints}
              url={url}
              selected={selected}
            />
          )}
        />
      </div>
    )
  })
}

export default ExternalCollectionsForm
