import { Dispatch } from 'react'

import { adaloBackendAxios } from 'utils/io/http/axios'

import { RESET } from '..'

export const FETCH_BASE_URL = 'FETCH_BASE_URL'

type BaseURL = {
  status: number
}

type BaseURLDispatch = {
  type: string
  payload: BaseURL
}

// Actions
export const validateXanoBaseUrl =
  (url: string, token: string, appId: string) =>
  async (dispatch: Dispatch<BaseURLDispatch>): Promise<void> => {
    try {
      const queryToken = token ? `&token=${token}` : ''
      const result = await adaloBackendAxios.get<BaseURL>(
        `/external-databases/xano/validate/base-url?apiUrl=${url}&appId=${appId}${queryToken}`
      )

      return dispatch({
        type: FETCH_BASE_URL,
        payload: result,
      })
    } catch (error) {
      return dispatch({
        type: FETCH_BASE_URL,
        payload: { status: 400 },
      })
    }
  }

export const resetBaseUrl =
  () =>
  (dispatch: Dispatch<Omit<BaseURLDispatch, 'payload'>>): void => {
    return dispatch({
      type: RESET,
    })
  }
