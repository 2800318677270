type UsageStat = {
  title: string
  stat: string
  icon: React.Component | null
}

export const generateUsageStats = (
  pubCount: number,
  pubLimit: number,
  testCount: number,
  testLimit: number,
  seatCount: number,
  seatLimit: number,
  icons: Record<string, React.Component> = {}
): Array<UsageStat> => {
  const stats = [
    {
      title: 'Published Apps',
      stat: `${pubCount} out of ${pubLimit}`,
      icon: icons['published-apps'] || null,
    },
    {
      title: 'Test Apps',
      stat: `${testCount.toLocaleString('en-US')} of ${testLimit}`,
      icon: icons['test-apps'] || null,
    },
    {
      title: 'Editor Seats',
      stat: `${seatCount} of ${seatLimit}`,
      icon: icons['editor-seats'] || null,
    },
  ]

  return stats
}
