import React, { Component } from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import Icon from 'components/Shared/Icon'

import Tooltip from '../Tooltip'
import Loading from '../Loading'
import './Button.scss'

export default class Button extends Component {
  renderContent() {
    const {
      className,
      to,
      target,
      text,
      outlined,
      large,
      medium,
      small,
      block,
      danger,
      secondary,
      white,
      component,
      loading,
      children,
      teal,
      darkPink,
      orange,
      yellow,
      green,
      purple,
      gray,
      darkGray,
      red,
      pink,
      black,
      square,
      focussed,
      padded,
      fluid,
      icon,
      iconSize,
      listAddButton,
      fitContent,
      hasBackground,
      bigMarginTop,
      flat,
      iconSide = 'left',
      gradient,
      transcosmosRed,
      ...props
    } = this.props

    props.disabled = props.disabled || loading

    let ButtonComponent = to && !props.disabled ? Link : 'button'

    if (to && target === '_blank') {
      ButtonComponent = 'a'

      props.href = to
      props.target = target
    }

    if (component) {
      ButtonComponent = component
    }

    return (
      <ButtonComponent
        {...props}
        to={to}
        className={classNames('btn', className, {
          'btn-danger': danger,
          'btn-block': block,
          'btn-text': text,
          'btn-outlined': outlined,
          'btn-small': small,
          'btn-medium': medium,
          'btn-large': large,
          'btn-secondary': secondary,
          'btn-white': white,
          'btn-loading': loading,
          'btn-teal': teal,
          'btn-dark-pink': darkPink,
          'btn-orange': orange,
          'btn-yellow': yellow,
          'btn-green': green,
          'btn-purple': purple,
          'btn-gray': gray,
          'btn-dark-gray': darkGray,
          'btn-red': red,
          'btn-pink': pink,
          'btn-black': black,
          'btn-focussed': focussed,
          'btn-square': square,
          'btn-padded': padded,
          'btn-fluid': fluid,
          'btn-list-add': listAddButton,
          'btn-fit-content': fitContent,
          'btn-background': hasBackground,
          'btn-big-margin-top': bigMarginTop,
          'btn-flat': flat,
          'btn-gradient': gradient,
          'btn-transcosmos-red': transcosmosRed,
          'icon-right': iconSide === 'right',
        })}
      >
        {icon && iconSide === 'left' ? (
          <Icon
            type={icon}
            small={iconSize === 'small'}
            medium={iconSize === 'medium'}
            big={iconSize === 'big'}
          />
        ) : null}
        {children}
        {loading ? (
          <div className="btn-loading-loader">
            <Loading small />
          </div>
        ) : null}
        {icon && iconSide === 'right' ? (
          <Icon
            type={icon}
            small={iconSize === 'small'}
            medium={iconSize === 'medium'}
            big={iconSize === 'big'}
          />
        ) : null}
      </ButtonComponent>
    )
  }

  render() {
    const { tooltip, small, tooltipLink } = this.props
    let { placement } = this.props

    if (tooltip) {
      let text = tooltip

      if (tooltip?.text) text = tooltip.text
      if (tooltip?.placement) placement = tooltip.placement

      return (
        <Tooltip
          placement={placement}
          tooltip={text}
          small={small}
          link={tooltipLink}
        >
          {this.renderContent()}
        </Tooltip>
      )
    }

    return this.renderContent()
  }
}

Button.defaultProps = {
  type: 'contained',
}
