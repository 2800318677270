import React, { ReactElement } from 'react'
import ReactDOM from 'react-dom'
import ReactNative from 'react-native'

import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import type { Store } from 'redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'animate.css/animate.min.css'

import { connectDbAssistantSocket } from 'utils/io/dbAssistant'

import * as i18n from './i18n'
import App from './components/App'
import { connectSocket, forceSaveComponents } from './utils/io'
import store from './redux-store'

import './shims'

const loadApp = (): void => {
  // Set window globals for React, and Proton
  window.React = React
  window.ReactNative = ReactNative
  window.protonLibraries = {}

  const beforeUnload = () => {
    forceSaveComponents()
  }

  window.onbeforeunload = beforeUnload

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      forceSaveComponents()
    }
  })

  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://6c6c4d20a449413590a7cc9bc08bfef2@sentry.io/1299301',
      integrations: [new BrowserTracing()],
      denyUrls: [/s3\.amazonaws\.com\/component-marketplace-(dev|prod)/i],
      ignoreErrors: [
        'Component Error [libraryComponent]',
        'Non-Error exception captured with keys: _pendo_',
      ],
      sampleRate: 0.1, // error sample rate
      tracesSampleRate: 0.01,
      ...(process.env['REACT_APP_SENTRY_RELEASE_VERSION'] && {
        release: process.env['REACT_APP_SENTRY_RELEASE_VERSION'],
      }),
    })
  }

  connectSocket(store)
  connectDbAssistantSocket(store as Store)

  ReactDOM.render(
    (
      <Provider store={store as Store}>
        <App />
        <ToastContainer />
      </Provider>
    ) as ReactElement,
    document.getElementById('root') as HTMLElement
  )
}

i18n
  .init()
  .then(loadApp)
  .catch(err => console.error('PROBLEM LOADING APP', err))
