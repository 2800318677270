import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { timeFormat } from 'd3-time-format'
import { formatDateOnly } from 'utils/datetime'
import moment from 'moment'

import { adaloBackendAxios } from 'utils/io/http/axios'
import { isSupportedOnWeb } from 'utils/platform'

import { getApp } from 'ducks/apps'
import { getDatasources } from 'ducks/apps/datasources'

import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'
import Loading from 'components/Shared/Loading'
import Dropdown from 'components/App/Navbar/Dropdown'
import DatePicker from 'react-datepicker'

import UserChart from './Modules/UserChart'
import CumulativeSignupsChart from './Modules/CumulativeSignupsChart'
import EngagedUsersChart from './Modules/EngagedUsers'
import TotalUsers from './Modules/TotalUsers'
import MostVisited from './Modules/MostVisited'
import DevicesUsed from './Modules/DevicesUsed'
import UserLocation from './Modules/UserLocation'
import GranularityToggle from './GranularityToggle'
import './Analytics.css'

const maxPeriod = process.env.REACT_APP_MAX_MONTH_ANALYTICS_PERIOD || 16

const options = {
  day: 'Last 3 Days',
  week: 'Last 7 Days',
  month: 'Last 30 Days',
  three_months: 'Last 90 Days',
  max_period: `Last ${maxPeriod} Months`,
  custom: 'Custom',
}

const panelTransitionMs = 400

class AnalyticsPanel extends Component {
  state = {
    dimensions: null,
    range: 'month',
    viewDate: false,
    granularity: 'day',
  }

  componentDidMount() {
    setTimeout(this.fetchData, panelTransitionMs)
  }

  fetchData = async () => {
    const { range } = this.state
    const { app } = this.props

    let lifetimeStart = app?.createdAt
    const lifetimeEnd = new Date()

    if (lifetimeStart) {
      lifetimeStart = new Date(formatDateOnly(lifetimeStart))
    } else {
      lifetimeStart = new Date()
    }

    this.setState(
      {
        dimensions: {
          width: this.container ? this.container.offsetWidth : 100,
          height: this.container ? this.container.offsetHeight : 100,
        },
        lifetimeStart,
        lifetimeEnd,
      },
      this.getDateRange(range)
    )
  }

  getTotalSignups = async () => {
    const { appId } = this.props
    const queryType = 'all'

    const data = await adaloBackendAxios.post(`/analytics`, {
      appId,
      queryType,
    })

    return data.data.data
  }

  handleChange = propertyName => value => {
    const { range, start, end } = this.state

    if (propertyName === 'selectedStart' || propertyName === 'selectedEnd') {
      this.setState({ [propertyName]: value })
    } else if (propertyName === 'button') {
      const { selectedStart, selectedEnd } = this.state

      if (selectedStart && selectedEnd) {
        if (Date.parse(selectedStart) < Date.parse(selectedEnd)) {
          this.setState({ viewDate: false }, this.getDateRange(range))
        }
      }
    } else if (propertyName === 'custom') {
      this.setState({
        range: propertyName,
        viewDate: true,
        selectedStart: start,
        selectedEnd: end,
      })
    } else {
      this.setState(
        { range: propertyName, viewDate: false },
        this.getDateRange(propertyName)
      )
    }
  }

  handleToggle = value => {
    const { start, end, granularity } = this.state
    const difference = moment.duration(moment(end).diff(moment(start))).asDays()

    switch (value) {
      case 'month':
        value = difference < 90 ? granularity : value

        break
      case 'week':
        value = difference < 14 ? 'day' : value

        break
      case 'day':
        break
    }

    this.setState({ granularity: value })
  }

  handleClose = () => {
    this.setState({ viewDate: false, selectedStart: null, selectedEnd: null })
  }

  getDateRange = range => {
    let { selectedStart, selectedEnd, granularity } = this.state

    let start = new Date()
    let end = new Date()

    switch (range) {
      case 'day':
        start.setDate(end.getDate() - 3)

        break
      case 'week':
        start.setDate(end.getDate() - 7)

        break
      case 'month':
        start.setDate(end.getDate() - 30)

        break
      case 'three_months':
        start.setDate(end.getDate() - 90)

        break
      case 'max_period':
        start.setMonth(end.getMonth() - Number(maxPeriod))

        break
      case 'custom':
        start = selectedStart
        end = selectedEnd

        break
    }

    const formatTime =
      start.getFullYear() !== end.getFullYear()
        ? timeFormat('%b %d, %Y')
        : timeFormat('%b %d')

    let dateHeader = `${formatTime(start)} - ${formatTime(end)}`

    if (start.getFullYear() === end.getFullYear()) {
      dateHeader = `${dateHeader}, ${start.getFullYear()}`
    }

    start = new Date(start)
    end = new Date(end)

    const difference = moment.duration(moment(end).diff(moment(start))).asDays()

    switch (granularity) {
      case 'month':
        granularity = difference <= 89 ? 'day' : granularity

        break
      case 'week':
        granularity = difference <= 14 ? 'day' : granularity

        break
      case 'day':
        break
    }

    this.setState({ dateHeader, start, end, granularity })
  }

  renderContent() {
    const {
      dimensions,
      start,
      end,
      range,
      dateHeader,
      granularity,
      viewDate,
      selectedStart,
      selectedEnd,
      lifetimeStart,
      lifetimeEnd,
    } = this.state

    const { appId, tableId, datasourceId, app } = this.props
    const { primaryPlatform } = app
    const { width } = dimensions

    if (typeof lifetimeStart !== 'undefined') {
      return (
        <div className="app-analytics-container">
          <div className="app-analytics-main-container">
            <TotalUsers className="app-analytics-module-total" appId={appId} />
            <EngagedUsersChart
              className="app-analytics-module-engaged"
              appId={appId}
              width={width}
              lifetimeDifference={moment
                .duration(moment(lifetimeEnd).diff(moment(lifetimeStart)))
                .asDays()}
            />
          </div>
          <div className="app-analytics-user-container">
            <div className="analytics-selector-container">
              <GranularityToggle
                start={start}
                end={end}
                onClick={this.handleToggle}
                granularity={granularity}
              />
              <Dropdown
                title={
                  <div
                    className="range-dropdown-current-app"
                    title="range-selector"
                  >
                    <span className="range-dropdown-current-app-name">
                      {range === 'custom' &&
                      selectedStart &&
                      selectedEnd &&
                      !viewDate
                        ? `${dateHeader}`
                        : options[range]}
                    </span>
                    <Icon type="expand-vertical" />
                  </div>
                }
                className="range-dropdown"
              >
                <div onClick={this.handleChange('day')}>
                  <span>{options.day}</span>
                </div>
                <div onClick={this.handleChange('week')}>
                  <span>{options.week}</span>
                </div>
                <div onClick={this.handleChange('month')}>
                  <span>{options.month}</span>
                </div>
                <div onClick={this.handleChange('three_months')}>
                  <span>{options.three_months}</span>
                </div>
                <div onClick={this.handleChange('max_period')}>
                  <span>{options.max_period}</span>
                </div>
                <div onClick={this.handleChange('custom')}>
                  <span>{options.custom}</span>
                </div>
              </Dropdown>
              {viewDate ? (
                <div className="date-picker-content">
                  <div
                    className="date-picker-close"
                    onMouseDown={this.handleClose}
                  />
                  <div className="app-analytics-date-picker-container">
                    <h3>Start Date</h3>
                    <DatePicker
                      className="analytics-date-picker"
                      name="start"
                      selected={selectedStart}
                      value={selectedStart}
                      onChange={this.handleChange('selectedStart')}
                      Format="yyyy-MM-dd"
                    />
                    <h3>End Date</h3>
                    <DatePicker
                      className="analytics-date-picker"
                      name="end"
                      selected={selectedEnd}
                      value={selectedEnd}
                      onChange={this.handleChange('selectedEnd')}
                      Format="yyyy-MM-dd"
                    />
                    <Button onClick={this.handleChange('button')}>Apply</Button>
                  </div>
                </div>
              ) : null}
            </div>
            <UserChart
              appId={appId}
              width={width}
              start={start}
              end={end}
              range={range}
              granularity={granularity}
            />
          </div>
          <div className="app-analytics-engagement-container">
            <div className="app-analytics-row-container">
              <CumulativeSignupsChart
                className="app-analytics-module-cumuchart"
                appId={appId}
                width={width}
                start={start}
                end={end}
                range={range}
                granularity={granularity}
                datasourceId={datasourceId}
                tableId={tableId}
                lifetimeStart={lifetimeStart}
                lifetimeEnd={lifetimeEnd}
              />
              <MostVisited
                className="app-analytics-module-visited"
                appId={appId}
                start={start}
                end={end}
                range={range}
                width={width}
              />
            </div>
            <div className="app-analytics-row-container">
              <UserLocation
                className="app-analytics-module-locations"
                appId={appId}
                start={start}
                end={end}
                range={range}
                width={width}
              />
              {isSupportedOnWeb(primaryPlatform) ? null : (
                <DevicesUsed
                  className="app-analytics-module-devices"
                  appId={appId}
                  start={start}
                  end={end}
                  range={range}
                  width={width}
                />
              )}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="loading-div">
        <Loading />
      </div>
    )
  }

  render() {
    const { dimensions } = this.state

    return (
      <div>
        <div ref={el => (this.container = el)} />
        <h2 className="left-panel-title">
          <Icon type="analytics" />
          Analytics
        </h2>
        {dimensions && this.renderContent()}
      </div>
    )
  }
}

const mapStateToProps = (state, { appId }) => {
  const datasources = getDatasources(state, appId)
  const app = getApp(state, appId)
  const datasource = datasources?.length === 1 && datasources[0]

  let tableId
  let datasourceId

  if (datasource) {
    datasourceId = datasource.id
    tableId = datasource.auth.table
  }

  return { datasourceId, tableId, app }
}

export default withRouter(connect(mapStateToProps)(AnalyticsPanel))
