import React, { useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import { SubmissionError } from 'redux-form'
import { useTranslation } from 'react-i18next'
import QS from 'qs'

import { setCurrentUserToken } from 'ducks/users/index.ts'

import { parseJWT } from 'utils/auth'
import { signup } from 'utils/io'
import { getReferral } from 'utils/rewardful'
import { trackSignupEvent } from 'utils/facebookPixelEvents'

import AuthPage from './AuthV2/AuthPage.tsx'
import SignupForm from './AuthV2/SignupForm.tsx'

const Signup = props => {
  const { location, children, history, setCurrentUserToken } = props

  const { i18n } = useTranslation()

  const { initialValues, error, inviteToken, redirect } = useMemo(() => {
    const { location } = props
    const queryParams = QS.parse(location.search, { ignoreQueryPrefix: true })
    const { token, redirect } = queryParams

    let initialValues = {}
    let error = null

    if (token) {
      const tokenBody = parseJWT(token)

      if (tokenBody) {
        initialValues = { email: tokenBody.email }
      } else {
        error = 'Your invitation has expired. Invitations expire after 7 days.'
      }
    }

    return { initialValues, error, inviteToken: token, redirect }
  }, [location])

  useEffect(() => {
    const googleTagManagerScript = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KSCWMSLF');
`

    const script = document.createElement('script')
    script.setAttribute('id', 'google-tag-manager-script')
    script.text = googleTagManagerScript
    document.head.appendChild(script)

    const noscript = document.createElement('noscript')
    noscript.setAttribute('id', 'google-tag-manager-body')

    const iframe = document.createElement('iframe')
    iframe.setAttribute(
      'src',
      'https://www.googletagmanager.com/ns.html?id=GTM-KSCWMSLF'
    )
    iframe.setAttribute('height', '0')
    iframe.setAttribute('width', '0')
    iframe.setAttribute('style', 'display:none;visibility:hidden')
    iframe.setAttribute('title', 'google-tag-manager-iframe')

    noscript.appendChild(iframe)
    document.body.appendChild(noscript)
  }, [])

  const handleSubmit = values =>
    new Promise((resolve, reject) => {
      const rewardfulToken = getReferral()

      const region = i18n.language === 'ja' ? 'japan' : null

      const data = {
        ...values,
        inviteToken,
        rewardfulToken,
        region,
      }

      signup(data, ({ success, errors, sessionToken, newOrg }) => {
        if (!success) {
          return reject(new SubmissionError(errors))
        }

        setCurrentUserToken(sessionToken)
        trackSignupEvent()

        const nextPage = 'email-validation'

        let redirectPage = `/${nextPage}`

        if (inviteToken) {
          redirectPage = `/${nextPage}?token=${inviteToken}`
        }

        if (redirectPage) {
          if (!String(redirectPage).startsWith('/')) {
            redirectPage = `/${redirectPage}`
          }

          history.push(redirectPage)
        } else if (newOrg) {
          const newOrgNextPage = '/email-validation'
          history.push(newOrgNextPage)
        } else {
          history.push('/')
        }

        resolve()
      })
    })

  const isInvite = location.pathname === '/accept-invite'

  return (
    <AuthPage name="signup-page" error={error} version={2}>
      <SignupForm
        isInvite={isInvite}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        redirect={redirect}
      />
      {children}
    </AuthPage>
  )
}

export default connect(null, { setCurrentUserToken })(Signup)
