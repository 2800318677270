import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { instance } from '../../i18n'

import Button from '../Shared/Button'
import WrappedInput from '../Shared/Forms/WrappedInput'
import AuthPageLogo from './Logo'

const FORM_NAME = 'forgotPasswordForm'

export const validate = data => {
  const errors = {}

  if (!data.email) {
    errors.email = instance.t('Email is required')
  } else if (!data.email.match(/^.+@([\w\-]+\.)+\w{2,}$/)) {
    errors.email = instance.t('Please enter a valid email address')
  }

  return errors
}

const ForgotPasswordForm = ({ handleSubmit, error, submitSucceeded }) => {
  const { t } = useTranslation()

  return (
    <form onSubmit={handleSubmit} className="auth-form">
      <AuthPageLogo />
      <h1>{t('Forgot Password')}</h1>
      <p>
        {t('Please enter your email and a password reset link will be sent')}
      </p>
      <Field
        autoFocus
        name="email"
        placeholder="me@example.com"
        type="email"
        label={t('Email Address')}
        component={WrappedInput}
      />
      <div className="auth-form-submission-row">
        <Button large yellow disabled={submitSucceeded}>
          {t('Send')}
        </Button>
      </div>
      {error && <div className="auth-form-error">{error}</div>}
      {submitSucceeded && (
        <div className="auth-form-error">
          {t('A password recovery link has been sent to your email.')}
        </div>
      )}
    </form>
  )
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(ForgotPasswordForm)
