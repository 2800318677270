import React from 'react'
import { useSelector } from 'react-redux'

import EmptyState from 'components/Shared/EmptyState'
import Loading from 'components/Shared/Loading'

import { getPricingPlans } from 'ducks/billing'

import { isCurrentPlanType } from 'utils/billing.ts'

import UsageStats from './UsageStats'

import './UsageSection.scss'

const UsageSection = props => {
  const { organization } = props

  if (!organization) {
    return null
  }

  const { planType } = organization

  const pricingPlans = useSelector(state => getPricingPlans(state))
  const isNewPlanType = isCurrentPlanType(planType)

  if (!pricingPlans) {
    return (
      <EmptyState className="usage-section">
        <Loading />
      </EmptyState>
    )
  }

  return (
    <>
      <h3 className="settings-header">Monthly Usage</h3>
      {isNewPlanType && (
        <UsageStats organization={organization} pricingPlans={pricingPlans} />
      )}
    </>
  )
}

export default UsageSection
