import React from 'react'
import { connect } from 'react-redux'
import { getBoundingBox } from '@adalo/utils'
import { listTypes } from '@adalo/constants'

import { getSelection } from 'ducks/editor/selection'
import { getListItemWidth } from 'ducks/editor/objects/helpers/getListItemWidth'
import getCustomListProperties from 'ducks/editor/objects/helpers/getCustomListProperties'
import { updateObject } from 'ducks/editor/objects'

import { defaultListChildren } from 'utils/lists'
import { getDeviceChildren } from 'utils/operations/getDeviceChildren'
import { scaleValue } from 'utils/zoom'

import GroupWrapper from '../Group/GroupWrapper'
import BaseObject from '../BaseObject'

class List extends BaseObject {
  static defaultChildren = defaultListChildren
  static PAGINATION_CONTROL_HEIGHT = 20
  static PAGINATION_MARGIN = 8
  static TOTAL_PAGINATION_HEIGHT =
    List.PAGINATION_CONTROL_HEIGHT + List.PAGINATION_MARGIN

  static defaultProps = {
    children: defaultListChildren,
    object: {
      children: defaultListChildren,
      dataBinding: {
        options: {
          paginate: true,
          manualPagination: false,
          pageSize: 20,
        },
      },
    },
  }

  getRowHeight = (includeRowMargin = false) => {
    const { object, deviceType } = this.props
    const { rowMargin } = getCustomListProperties(object, deviceType)
    let rowHeight = 0

    const objectChildren =
      object && object.children
        ? getDeviceChildren(object.children, deviceType)
        : List.defaultChildren

    if (objectChildren.length > 0) {
      const bbox = getBoundingBox(objectChildren)
      const topOffset = bbox.y - object.y
      const margin = includeRowMargin ? rowMargin : 0
      rowHeight = bbox.height + topOffset + margin
    } else {
      rowHeight = 80
    }

    if (rowHeight <= 0) {
      rowHeight = 20
    }

    return rowHeight
  }

  renderChildCopies() {
    const { children, height, zoom, object, deviceType } = this.props
    const { listType, columnCount, rowMargin } = getCustomListProperties(
      object,
      deviceType
    )
    const rowHeight = this.getRowHeight(true)
    const prototype = Array.isArray(children) && children[0]

    const contentHeight = height

    if (!prototype) {
      return this.renderPaginationControls()
    }

    const elements = []
    let computedColumnCount = 1
    let columnWidth = object.width
    const spacing = rowMargin || 0

    if (listType === listTypes.GRID) {
      computedColumnCount = columnCount || 1
      columnWidth =
        (object.width - (computedColumnCount - 1) * spacing) /
        computedColumnCount
    }

    let lastYOffset = 0
    for (
      let offset = 0;
      offset + rowHeight <= contentHeight;
      offset += rowHeight
    ) {
      for (let i = 0; i < computedColumnCount; i += 1) {
        if (i === 0 && offset === 0) {
          continue
        }

        const yOffset = scaleValue(offset, zoom)
        const xOffset = scaleValue(i * (columnWidth + spacing), zoom)

        const row = (
          <React.Fragment>
            {children.map(child => React.cloneElement(child))}
          </React.Fragment>
        )

        elements.push(
          <g
            transform={`translate(${xOffset}, ${yOffset})`}
            pointerEvents="none"
            opacity="0.5"
            key={elements.length}
          >
            {row}
          </g>
        )

        lastYOffset = yOffset
      }
    }

    elements.push(this.renderPaginationControls(lastYOffset))

    return elements
  }

  renderPaginationControls(lastYOffset) {
    const { object, xScaled, yScaled } = this.props
    const { dataBinding } = object

    if (!dataBinding?.options?.manualPagination) return null

    const rowHeight = this.getRowHeight(true)
    const controlWidth = 80
    const xPosition = xScaled + this.props.widthScaled - controlWidth
    const yPosition =
      yScaled + rowHeight / 2 + lastYOffset + List.PAGINATION_MARGIN

    return (
      <g
        className="pagination-controls"
        transform={`translate(${xPosition}, ${yPosition})`}
      >
        <g transform="translate(15, 0)" style={{ cursor: 'pointer' }}>
          <text x={0} y={14} fill="#00000020" fontSize={10} textAnchor="middle">
            ←
          </text>
        </g>
        <rect
          x={30}
          y={0}
          width={20}
          height={List.PAGINATION_CONTROL_HEIGHT}
          fill="#f5f5f5"
          strokeWidth={1}
          rx={4}
        />
        <text x={40} y={14} fill="#000" fontSize={10} textAnchor="middle">
          1
        </text>
        <g transform="translate(65, 0)" style={{ cursor: 'pointer' }}>
          <text x={0} y={14} fill="#000" fontSize={10} textAnchor="middle">
            →
          </text>
        </g>
      </g>
    )
  }

  renderCellBbox() {
    const { xScaled, yScaled, zoom, object, deviceType } = this.props
    const rowHeight = this.getRowHeight()
    const rowHeightScaled = scaleValue(rowHeight, zoom)
    const dash = scaleValue(6, zoom)

    const boxWidth = getListItemWidth(object, deviceType)

    return (
      <rect
        stroke="#f40"
        strokeWidth={1}
        strokeDasharray={`${dash}, ${dash}`}
        fill="none"
        x={xScaled}
        y={yScaled}
        width={scaleValue(boxWidth, zoom)}
        height={rowHeightScaled}
      />
    )
  }

  render() {
    const {
      children,
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      backgroundColor,
      opacity,
      onMouseEnter,
      isSelected,
      object,
      editorResizingProps,
    } = this.props

    const totalHeight = heightScaled
    const updatedObject = {
      ...object,
      height: totalHeight,
      bbox: {
        ...object.bbox,
        height: totalHeight,
      },
    }

    return (
      <>
        <GroupWrapper
          object={updatedObject}
          editorResizingProps={editorResizingProps}
        >
          <g className="group" style={{ opacity }} onMouseEnter={onMouseEnter}>
            {isSelected ? this.renderCellBbox() : null}
            <rect
              x={xScaled}
              y={yScaled}
              width={widthScaled}
              height={totalHeight}
              fill={backgroundColor || 'none'}
              onMouseDown={this.handleMouseDown}
              onDoubleClick={this.handleDoubleClick}
            />
            {this.renderChildCopies()}
            {children}
          </g>
        </GroupWrapper>
      </>
    )
  }
}

const mapStateToProps = (state, { id }) => ({
  isSelected: getSelection(state).includes(id),
})

const mapDispatchToProps = {
  updateObject,
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
