import i18n, { TFunction } from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const configs = {
  supportedLngs: ['en', 'ja'],
  nonExplicitSupportedLngs: true,
  fallbackLng: 'en',
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
}

export const init = async (): Promise<TFunction> =>
  i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(configs)

export const instance = i18n
