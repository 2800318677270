import React, { useState, useContext, useEffect } from 'react'
import { Field } from 'redux-form'
import { CardElement } from '@stripe/react-stripe-js'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import { getPricingPlans } from 'ducks/billing'
import {
  getAnnualToggle,
  getUpdateCardFlag,
  getUpdatePaymentSettingsFlag,
  setUpdateCardFlag,
  setAnnualFlag,
  getSubscription,
  getSelectedPlanValue,
  getPaymentRouteType,
  fetchCoupon,
  getProratedCharge,
  getUnusedTimeAmount,
  fetchProratedCharge,
  getCouponAmount,
} from 'ducks/trialWarning'
import { formatDate, humanizeDate } from 'utils/datetime'
import {
  getPlanPrice,
  isCurrentPlanType,
  PRICE_TYPE_MONTHLY,
  PRICE_TYPE_YEARLY,
} from 'utils/billing.ts'

import SettingsFormField from 'components/Shared/Forms/SettingsFormField'
import { PaymentForm as SharedPaymentForm } from 'components/Shared/PaymentForm'
import WrappedNativeSelect from 'components/Shared/Forms/WrappedNativeSelect'
import Button from 'components/Shared/Button'
import {
  PlanChangeContext,
  PRICE_PER_APP_ADDON,
  PRICE_PER_TEAM_MEMBER_ADDON,
} from 'components/Shared/TrialWarning/PlanChangeContext'

import { COUNTRY_CODES } from '../../../constants'

const DISCOUNT_FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const PaymentForm = props => {
  const [couponVisible, setCouponVisible] = useState(false)
  const [coupon, setCoupon] = useState(null)
  const [couponError, setCouponError] = useState(null)
  const [couponSuccess, setCouponSuccess] = useState(false)
  const showCoupon = () => setCouponVisible(true)

  const {
    additionalPublishedApps,
    additionalTeamMembers,
    appOverage,
    teamMemberOverage,
  } = useContext(PlanChangeContext)

  const appOverageAmount = appOverage * PRICE_PER_APP_ADDON
  const teamMemberOverageAmount =
    teamMemberOverage * PRICE_PER_TEAM_MEMBER_ADDON

  const COUNTRY_OPTIONS = COUNTRY_CODES.map(c => ({
    label: c.name,
    value: c.code,
  }))

  const {
    hideCoupon,
    annual,
    subscription = {},
    updateCardFlag,
    paymentRouteType,
    setUpdateCardFlag,
    setAnnualFlag,
    updatePaymentSettings,
    selectedPlanValue,
    planName,
    pricingPlans,
    submitting,
    proratedTotal,
    unusedTimeTotal,
    couponTotal,
    fetchProratedCharge,
    organizationId,
    couponCode,
  } = props

  const updateCardFlagTrue = () => setUpdateCardFlag(true)

  useEffect(() => {
    fetchProratedCharge(
      organizationId,
      selectedPlanValue,
      annual,
      couponCode,
      additionalPublishedApps,
      additionalTeamMembers
    )
  }, [])

  const onToggle = () => {
    fetchProratedCharge(
      organizationId,
      selectedPlanValue,
      !annual,
      couponCode,
      additionalPublishedApps,
      additionalTeamMembers
    )

    setAnnualFlag(!annual)
  }

  const getCoupon = async () => {
    setCoupon(null)
    setCouponError(null)
    setCouponSuccess(false)

    const data = await fetchCoupon(couponCode)

    if (!data.error) {
      setCoupon(data)
      setCouponSuccess(true)

      fetchProratedCharge(
        organizationId,
        selectedPlanValue,
        annual,
        couponCode,
        additionalPublishedApps,
        additionalTeamMembers
      )

      return
    }

    fetchProratedCharge(
      organizationId,
      selectedPlanValue,
      annual,
      null,
      additionalPublishedApps,
      additionalTeamMembers
    )

    const errorMessage = data?.error || 'An unknown error occurred'

    setCouponError(errorMessage)
  }

  const { planType } = subscription

  let { cardNumber = false, nextBillDate = false, cardBrand } = subscription

  const hasCard = subscription?.configured && cardNumber && cardBrand

  const renderSavedCards =
    hasCard && !updateCardFlag && subscription.planType !== 'free'

  const renderNewPlansPaymentForm =
    isCurrentPlanType(selectedPlanValue) && !updatePaymentSettings

  const renderSwitchToYearly =
    (paymentRouteType === 'upgrade' || (!selectedPlanValue && !hideCoupon)) &&
    (!selectedPlanValue || selectedPlanValue !== planType)

  let printedPlanName
  let initialPlanCost
  let planCost
  let proratedNetAmount
  let yearlySavings

  if (renderNewPlansPaymentForm) {
    const type = annual ? '12 Months' : 'Monthly'
    printedPlanName = `${planName} ${type}`

    const monthlyPlanCharge = getPlanPrice(
      selectedPlanValue,
      pricingPlans,
      PRICE_TYPE_MONTHLY
    )

    const yearlyPlanCharge = getPlanPrice(
      selectedPlanValue,
      pricingPlans,
      PRICE_TYPE_YEARLY
    )

    planCost = annual ? yearlyPlanCharge : monthlyPlanCharge
    initialPlanCost = annual ? monthlyPlanCharge * 12 : monthlyPlanCharge

    const yearlySavingsFmt = DISCOUNT_FORMATTER.format(
      (initialPlanCost - yearlyPlanCharge) / initialPlanCost
    )

    yearlySavings = `-${yearlySavingsFmt}`
  } else {
    if (annual) {
      if (selectedPlanValue === 'pro' || selectedPlanValue === 'individual') {
        printedPlanName = 'Pro Plan 12 Months'
        initialPlanCost = 600
        planCost = 468
        yearlySavings = '-$132'
      } else {
        printedPlanName = 'Business Plan 12 Months'
        initialPlanCost = 2400
        planCost = 1788
        yearlySavings = '-$612'
      }
    } else {
      if (selectedPlanValue === 'pro' || selectedPlanValue === 'individual') {
        printedPlanName = 'Monthly Pro Plan'
        initialPlanCost = 50
        planCost = 50
      } else {
        printedPlanName = 'Monthly Business Plan'
        initialPlanCost = 200
        planCost = 200
      }
    }
  }

  let amountOff
  let nextBill = planCost

  if (typeof unusedTimeTotal === 'number') {
    proratedNetAmount = unusedTimeTotal
  }

  if (coupon) {
    amountOff = coupon.percent_off
      ? planCost * (coupon.percent_off / 100)
      : coupon.amount_off / 100

    planCost = planCost - amountOff
    planCost = parseFloat(planCost.toFixed(2))
    amountOff = amountOff.toFixed(2).replace('.00', '')
    if (planCost < 0) planCost = 0

    nextBill = planCost

    if (coupon.duration !== 'forever') {
      if (coupon.duration_in_months) {
        if (
          coupon.duration_in_months <= 1 ||
          (coupon.duration_in_months <= 12 && annual)
        ) {
          nextBill = initialPlanCost
        }
      } else {
        nextBill = initialPlanCost
      }
    }
  }

  planCost += appOverageAmount + teamMemberOverageAmount

  if (!annual) {
    nextBill += appOverageAmount + teamMemberOverageAmount
  }

  if (typeof proratedTotal === 'number') {
    if (proratedTotal < 0) {
      planCost = 0
    } else {
      planCost = proratedTotal
    }

    if (couponTotal) {
      amountOff = couponTotal
      amountOff = amountOff.toFixed(2).replace('.00', '')
    }
  }

  const isSubscriptionAnnual = subscription?.planInterval === 'year'
  const isNewSubscriptionPeriod = annual !== isSubscriptionAnnual

  if (!nextBillDate || isNewSubscriptionPeriod) {
    if (annual) {
      const nextYearDate = new Date().setFullYear(new Date().getFullYear() + 1)
      nextBillDate = moment(nextYearDate).format('M/DD/YYYY')
    } else {
      const nextMonthDate = new Date().setMonth(new Date().getMonth() + 1)
      nextBillDate = moment(nextMonthDate).format('M/DD/YYYY')
    }
  } else {
    nextBillDate = formatDate(nextBillDate)
  }

  let margin

  if (updatePaymentSettings) {
    margin = 'auto'
  }

  const renderOldPaymentForm = () => {
    if (renderSavedCards) {
      return (
        <div className="payment-form payment-summary billing-payment-form">
          <div className="settings-form-row">
            <h2> Payment Details </h2>
            <div className="settings-form-input settings-stripe-input">
              Credit Card: {cardBrand.toUpperCase()} ending in •••• {cardNumber}
            </div>
            <div className="update-card-btn-wrapper">
              <a className="update-card-btn" onClick={updateCardFlagTrue}>
                Update card
              </a>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className="billing-payment-form payment-form"
          style={{
            margin,
          }}
        >
          <div className="settings-form-row">
            <h2> Payment Details </h2>
            <label>Credit or Debit Card</label>
            <div className="settings-form-input settings-stripe-input">
              <CardElement options={{ hidePostalCode: true }} />
            </div>
          </div>
          <Field
            small
            label="Name on Card"
            name="name"
            component={SettingsFormField}
          />
          <h2> Billing Details </h2>
          <div className="payment-form-country-input">
            <Field
              small
              displayName="Country of billing address"
              name="address_country"
              options={COUNTRY_OPTIONS}
              component={WrappedNativeSelect}
              label="Country of billing address"
            />
          </div>

          <Field
            small
            label="Street Address"
            name="address_line1"
            component={SettingsFormField}
          />
          <Field
            small
            label="City"
            name="address_city"
            component={SettingsFormField}
          />
          <Field
            small
            label="Postal Code"
            name="address_zip"
            component={SettingsFormField}
          />
        </div>
      )
    }
  }

  const renderPaymentForm = () => {
    if (renderNewPlansPaymentForm) {
      return (
        <div className="billing-payment-form">
          <SharedPaymentForm
            formName="trialModalSignup"
            includeAddress
            includeSavePaymentToggle={false}
            updateCardFlagTrue={updateCardFlagTrue}
          />
        </div>
      )
    }

    return renderOldPaymentForm()
  }

  const renderCoupon = () => {
    if (hideCoupon) {
      return null
    }

    if (couponVisible) {
      return (
        <div className="input-padding">
          <Field
            small
            autoFocus
            label="Discount Code"
            name="coupon"
            component={SettingsFormField}
          />
          <Button
            className="team-settings-form-button"
            type="button"
            onClick={getCoupon}
          >
            Apply
          </Button>
        </div>
      )
    } else {
      return (
        <a className="coupon-code-link" onClick={showCoupon}>
          Have a Discount Code?
        </a>
      )
    }
  }

  const renderSwitchToYearlyButton = () => {
    if (!renderSwitchToYearly) {
      return null
    }

    if (annual) {
      return (
        <a className="pay-monthly-btn" onClick={!submitting && onToggle}>
          No thanks, I'll pay the full monthly price
        </a>
      )
    }

    return (
      <a className="switch-to-yearly-btn" onClick={!submitting && onToggle}>
        SWITCH TO YEARLY SAVE 20%
      </a>
    )
  }

  const appAddonText = 'additional published apps'
  const teamMemberAddonText = 'additional app editor seats'

  const renderMonthlyBillingText = () => {
    let billingDescription = 'Your plan subscription'

    if (additionalPublishedApps || additionalTeamMembers) {
      if (additionalPublishedApps && additionalTeamMembers) {
        billingDescription = `${billingDescription}, ${appAddonText}, and ${teamMemberAddonText}`
      } else {
        billingDescription = `${billingDescription} and`

        if (additionalPublishedApps) {
          billingDescription = `${billingDescription} ${appAddonText}`
        }

        if (additionalTeamMembers) {
          billingDescription = `${billingDescription} ${teamMemberAddonText}`
        }
      }

      billingDescription = `${billingDescription} are all`
    } else {
      billingDescription = `${billingDescription} is`
    }

    billingDescription = `${billingDescription} billed monthly and will automatically renew for $${nextBill.toLocaleString(
      'en-US'
    )} on ${nextBillDate}`

    return <>{billingDescription}</>
  }

  const renderAnnualBillingText = () => {
    let addonDescription = null

    if (additionalPublishedApps || additionalTeamMembers) {
      addonDescription = 'Your'

      if (additionalPublishedApps && additionalTeamMembers) {
        addonDescription = `${addonDescription} ${appAddonText} and ${teamMemberAddonText}`
      } else {
        if (additionalPublishedApps) {
          addonDescription = `${addonDescription} ${appAddonText}`
        }

        if (additionalTeamMembers) {
          addonDescription = `${addonDescription} ${teamMemberAddonText}`
        }
      }

      addonDescription = (
        <p>{`${addonDescription} will automatically renew on the ${humanizeDate(
          moment(nextBillDate).date()
        )} of each month.`}</p>
      )
    }

    return (
      <>
        Your plan subscription is billed yearly and will automatically renew for
        ${nextBill.toLocaleString('en-US')} on {nextBillDate}
        {addonDescription}
      </>
    )
  }

  return (
    <div>
      {!updatePaymentSettings && (
        <div className="order-summary">
          <div className="order-summary-header">Order Summary</div>
          <div className="order-summary-content">
            <div className="left">{printedPlanName}</div>
            <div className="right">
              ${initialPlanCost.toLocaleString('en-US')}
            </div>
            {annual ? (
              <>
                <div className="clear-left left">Yearly Savings</div>
                <div className="clear-right right">
                  {yearlySavings.toLocaleString('en-US')}
                </div>
              </>
            ) : null}
            {proratedNetAmount ? (
              <div>
                <div className="clear-left left">Prorated adjustment</div>
                <div className="clear-right right">
                  {proratedNetAmount.toLocaleString('en-US')}
                </div>
              </div>
            ) : null}
            {additionalPublishedApps ? (
              <>
                <div className="clear-left left">
                  Additional Published Apps x{additionalPublishedApps}
                </div>
                <div className="clear-right right">
                  ${appOverageAmount.toLocaleString('en-US')}
                </div>
              </>
            ) : null}
            {additionalTeamMembers ? (
              <>
                <div className="clear-left left">
                  Additional Editor Seats x{additionalTeamMembers}
                </div>
                <div className="clear-right right">
                  ${teamMemberOverageAmount.toLocaleString('en-US')}
                </div>
              </>
            ) : null}
            {amountOff ? (
              <>
                <div className="clear-left left">Promotion Savings</div>
                <div className="clear-right right">
                  -${amountOff.toLocaleString('en-US')}
                </div>
              </>
            ) : null}
            <div className="clear-left">
              {renderCoupon()}
              {couponError ? (
                <div className="coupon-code-error">{couponError}</div>
              ) : null}
              {couponSuccess ? (
                <div className="coupon-code-success">Coupon Applied!</div>
              ) : null}
            </div>
          </div>
          <div className="order-summary-footer">
            <div className="clear-left left">
              <h2>Total</h2>
            </div>
            <div className="clear-right right">
              <h2>${planCost.toLocaleString('en-US')}</h2>
            </div>
          </div>
          <div className="order-summary-plan-renewal">
            {annual ? renderAnnualBillingText() : renderMonthlyBillingText()}
            <p> You can cancel any time.</p>
          </div>
          {renderSwitchToYearlyButton()}
        </div>
      )}

      {renderPaymentForm()}
    </div>
  )
}

const mapStateToProps = (state, { organizationId }) => {
  return {
    updateCardFlag: getUpdateCardFlag(state),
    hideCoupon: getUpdatePaymentSettingsFlag(state),
    annual: getAnnualToggle(state),
    subscription: getSubscription(state, organizationId),
    selectedPlanValue: getSelectedPlanValue(state),
    paymentRouteType: getPaymentRouteType(state),
    pricingPlans: getPricingPlans(state),
    updatePaymentSettings: getUpdatePaymentSettingsFlag(state),
    proratedTotal: getProratedCharge(state),
    unusedTimeTotal: getUnusedTimeAmount(state),
    couponTotal: getCouponAmount(state),
    organizationId,
  }
}

const connected = connect(mapStateToProps, {
  setUpdateCardFlag,
  setAnnualFlag,
  fetchProratedCharge,
})(PaymentForm)

export default withRouter(connected)
