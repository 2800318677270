import React, { useEffect } from 'react'
import i18n from 'i18next'
import { Redirect } from 'react-router-dom'

const LanguagePath = ({
  lang,
  children,
}: {
  lang: string
  children: React.ReactElement
}): React.ReactElement => {
  const path = window.location.pathname
  const page = path.split('/').pop() ?? 'login'
  const params = window.location.search
  const selectedLanguage = i18n?.language.includes('en-')
    ? 'en'
    : i18n?.language
  const supportedLngs =
    i18n?.options && i18n?.options?.supportedLngs
      ? i18n?.options?.supportedLngs
      : []

  useEffect(() => {
    if (supportedLngs.includes(lang)) {
      i18n
        .changeLanguage(lang)
        .then(() => {
          console.log('i18n - language changed to', lang)
        })
        .catch(err => {
          console.error('i18n - error changing language', err)
        })
    }
  }, [lang, supportedLngs])

  if (supportedLngs.includes(lang)) {
    return children
  }

  return <Redirect to={`/${selectedLanguage}/${page}${params}`} />
}

export default LanguagePath
