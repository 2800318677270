import React, { useEffect, useRef } from 'react'
import { reduxForm, Field, change, InjectedFormProps } from 'redux-form'
import { connect, useDispatch } from 'react-redux'
import { App, getApp, updateApp } from 'ducks/apps'
import { State } from 'ducks/editor/objects'
import SettingsFormField from 'components/Shared/Forms/SettingsFormField'
import { debounce } from 'lodash'
import './DeepLinking.scss'

type DeepLinkingProps = InjectedFormProps<{
  iosFallbackURL: string
  androidFallbackURL: string
}> & {
  appId: string
  deepLinking: {
    iosFallbackURL: string
    androidFallbackURL: string
    fallbackToPWA: boolean
  }
}

type FormValues = {
  iosFallbackURL: string
  androidFallbackURL: string
  fallbackToPWA: boolean
}

const validate = (values: {
  iosFallbackURL: string
  androidFallbackURL: string
}) => {
  const errors: { iosFallbackURL?: string; androidFallbackURL?: string } = {}
  const urlRegex = /^https?:\/\//i

  if (values.iosFallbackURL && !urlRegex.test(values.iosFallbackURL)) {
    errors.iosFallbackURL = 'URL must start with http:// or https://'
  }

  if (values.androidFallbackURL && !urlRegex.test(values.androidFallbackURL)) {
    errors.androidFallbackURL = 'URL must start with http:// or https://'
  }

  return errors
}

const DeepLinkingComponent: React.FC<
  DeepLinkingProps & InjectedFormProps<FormValues, DeepLinkingProps>
> = ({ appId, deepLinking }) => {
  const dispatch = useDispatch()
  const appIdRef = useRef(appId)
  const deepLinkingRef = useRef(deepLinking)

  useEffect(() => {
    appIdRef.current = appId
    deepLinkingRef.current = deepLinking
  }, [appId, deepLinking])

  const debouncedUpdateApp = useRef(
    debounce((name: string, value: string) => {
      dispatch(
        updateApp(appIdRef.current, {
          deepLinking: {
            ...deepLinkingRef.current,
            [name]: value,
          },
        })
      )
    }, 500)
  ).current

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedUpdateApp.cancel()
    }
  }, [debouncedUpdateApp])

  const toggleFallback = () => {
    dispatch(
      updateApp(appId, {
        deepLinking: {
          ...deepLinking,
          fallbackToPWA: !deepLinking.fallbackToPWA,
        },
      })
    )
  }

  const handleFallbackUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    dispatch(change('deepLinkingForm', name, value))

    debouncedUpdateApp(name, value)
  }

  return (
    <form autoComplete="off" className="deep-linking-form">
      <div className="redirection-and-fallback">
        <div className="redirection-and-fallback__header">
          <div className="redirection-and-fallback__label-container">
            <h5 className="redirection-and-fallback__title">
              Redirection and Fallback
            </h5>
            <p className="redirection-and-fallback__description">
              Redirect the user to the PWA version of the deep linking of the
              app in case the app is not installed
            </p>
          </div>
          <div className="redirection-and-fallback__switch-container">
            <div
              className={`switch ${
                deepLinking?.fallbackToPWA ? 'switch--on' : ''
              }`}
              role="button"
              tabIndex={0}
              onClick={toggleFallback}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault()
                  toggleFallback()
                }
              }}
            />
          </div>
        </div>

        {!deepLinking?.fallbackToPWA && (
          <div>
            <Field
              component={SettingsFormField}
              name="iosFallbackURL"
              label="Fallback URL iOS"
              placeholder="https://example.com"
              type="text"
              onChange={handleFallbackUrlChange}
            />
            <Field
              component={SettingsFormField}
              name="androidFallbackURL"
              label="Fallback URL Android"
              placeholder="https://example.com"
              type="text"
              onChange={handleFallbackUrlChange}
            />
          </div>
        )}
      </div>
    </form>
  )
}

const WrappedDeepLinkingComponent = reduxForm<FormValues, DeepLinkingProps>({
  form: 'deepLinkingForm',
  enableReinitialize: false,
  touchOnChange: true,
  touchOnBlur: true,
  validate,
})(DeepLinkingComponent)

const mapStateToProps = (state: State, ownProps: { appId: string }) => {
  const app: App = getApp(state, ownProps.appId)
  const { deepLinking }: { deepLinking?: DeepLinkingProps['deepLinking'] } =
    app || {}

  return {
    initialValues: {
      iosFallbackURL: deepLinking?.iosFallbackURL,
      androidFallbackURL: deepLinking?.androidFallbackURL,
      fallbackToPWA: deepLinking?.fallbackToPWA,
    },
    deepLinking,
    appId: ownProps.appId,
  }
}

export default connect(mapStateToProps)(WrappedDeepLinkingComponent)
