import React, { FormEvent, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  reduxForm,
  Field,
  change as reduxFormChange,
  InjectedFormProps,
  DecoratedFormProps,
  FormErrors,
} from 'redux-form'
import QS from 'qs'
import {
  GoogleReCaptchaProvider,
  IWithGoogleReCaptchaProps,
  withGoogleReCaptcha,
} from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import Button from 'components/Shared/Button'
import WrappedInput from 'components/Shared/Forms/WrappedInput'
import OnboardingLayout from 'components/Onboarding/Layout'
import AdaloLogotype from 'components/Onboarding/AdaloLogotype'
import TranscosmosLogotype from 'components/Onboarding/TranscosmosLogotype'

import { parseJWT } from 'utils/auth'
import { validateSignUpForm } from '../utils'
import { FormRow } from './utils'

const FORM_NAME = 'signupForm'

const getTermsUrl = () => {
  let { pathname, search } = window.location

  pathname = pathname.replace(/\/$/, '')
  const terms = pathname.includes('/terms') ? '' : '/terms'

  return `${pathname}${terms}${search}`
}

const TermsOfUse = () => {
  const { t } = useTranslation()

  return (
    <div className="terms-of-use">
      <span>
        {t('By signing up you agree to our')}{' '}
        <Link to={getTermsUrl()}>{t('Terms of Use.')}</Link>
      </span>
    </div>
  )
}

type Props = IWithGoogleReCaptchaProps & {
  submitFailed?: boolean
  error?: string
  isInvite?: boolean
  submitting?: boolean
  redirect?: string
  change: typeof reduxFormChange
  handleSubmit: () => Promise<void>
  executeRecaptcha: () => Promise<string>
}

type SignUpFormValues = {
  email: string
  password: string
  name: string
  terms: boolean
}

type SignUpFormFC = React.FC<Props & InjectedFormProps<SignUpFormValues, Props>>

const SignUpFormV2: SignUpFormFC = props => {
  const {
    submitFailed,
    error,
    isInvite,
    submitting,
    redirect,
    handleSubmit,
    googleReCaptchaProps,
    change,
  } = props

  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const isJapanese = useMemo(() => i18n.language === 'ja', [i18n.language])

  useEffect(() => {
    const { name, email, token } = QS.parse(window.location.search, {
      ignoreQueryPrefix: true,
    })

    if (token) {
      const tokenBody = parseJWT(token as string) as { email?: string }

      if (tokenBody) {
        const { email: tokenEmail } = tokenBody

        if (tokenEmail) {
          dispatch(reduxFormChange(FORM_NAME, 'email', tokenEmail))
        }
      }
    }

    if (email) {
      dispatch(reduxFormChange(FORM_NAME, 'email', email))
    }

    if (name) {
      dispatch(reduxFormChange(FORM_NAME, 'name', name))
    }
  }, [window.location.search])

  const redirectQuery = QS.stringify({ redirect }, { addQueryPrefix: true })

  const handleVerifyRecaptcha = async () => {
    const { executeRecaptcha } = googleReCaptchaProps

    if (!executeRecaptcha) {
      console.log('ReCaptcha has not been loaded')

      return
    }

    const token = await executeRecaptcha()

    if (!token) {
      console.info('Invalid user detected by ReCaptcha')

      return
    }

    change('token', token, undefined)

    await handleSubmit()
  }

  const callVerifyCaptcha = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault()

    handleVerifyRecaptcha().catch((err: Error) => {
      console.error(`Error verifying captcha: ${err.message}`)
    })
  }

  return (
    <OnboardingLayout>
      <form
        onSubmit={callVerifyCaptcha}
        className={classNames('auth-form-v2', {
          'auth-form-v2--transcosmos': isJapanese,
        })}
      >
        <div
          className={classNames('auth-form-v2__header', {
            'auth-form-v2__header--transcosmos': isJapanese,
          })}
        >
          <div className="auth-form-v2__header-logotype">
            <AdaloLogotype />
            {isJapanese && <TranscosmosLogotype />}
          </div>
          <h1>{t('Sign Up For Free!')}</h1>
        </div>
        <div className="auth-form-v2__content">
          <FormRow>
            <Field
              autoFocus={!isInvite}
              disabled={isInvite}
              name="email"
              placeholder="adalovelace@adalo.com"
              type="email"
              label={t('Email Address')}
              component={WrappedInput}
              autoComplete="email"
              maxLength={255}
            />
          </FormRow>
          <FormRow>
            <Field
              autoFocus={isInvite}
              name="password"
              placeholder="••••••••"
              type="password"
              label={t('Password')}
              component={WrappedInput}
              autoComplete="new-password"
            />
          </FormRow>
          <FormRow>
            <Field
              name="name"
              placeholder="Ada Lovelace"
              type="text"
              component={WrappedInput}
              label={t('Full Name')}
              autoComplete="name"
              maxLength={255}
            />
          </FormRow>
          <div className="auth-form-submission-row">
            <Button
              square
              gradient
              large
              teal={!isJapanese}
              transcosmosRed={isJapanese}
              fluid
              loading={submitting}
            >
              {t('Let’s Do This!')}
            </Button>
          </div>
          {error && submitFailed && (
            <div className="auth-form-error">{error}</div>
          )}
        </div>
        <div className="auth-form-v2__footer">
          <TermsOfUse />
          <div
            className={classNames('auth-form-link-v2', {
              'auth-form-link-v2--transcosmos': isJapanese,
            })}
          >
            {`${t('Already have an account?')} `}
            <Link to={`/login${redirectQuery}`} className="link-button">
              {t('Login')}
            </Link>
          </div>
        </div>
      </form>
    </OnboardingLayout>
  )
}

const validate = validateSignUpForm as (
  values: SignUpFormValues,
  props: DecoratedFormProps<SignUpFormValues, Props, string>
) => FormErrors<SignUpFormValues, string>

const WrappedSignUpForm = reduxForm<SignUpFormValues, Props>({
  form: FORM_NAME,
  validate,
  /* Terms are accepted on submit */
  initialValues: { terms: true },
})(SignUpFormV2)

const SignUpFormWithReCaptcha = withGoogleReCaptcha(WrappedSignUpForm)

const CAPTCHA_API_KEY = process.env['REACT_APP_RECAPTCHA_KEY'] as string

// TODO @danicunhac: fix component types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (props: React.ComponentProps<any>): JSX.Element => (
  <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_API_KEY}>
    <SignUpFormWithReCaptcha {...props} />
  </GoogleReCaptchaProvider>
)
