import React, { Component } from 'react'
import classNames from 'classnames'

import Tooltip from 'components/Shared/Tooltip'
import Icon from 'components/Shared/Icon'

import Button from '../../Button'
import ImageInput from '../ImageInput'
import FileInput from '../FileInput'

import './SettingsFormField.scss'

export default class SettingsFormField extends Component {
  renderImageInput() {
    const { helpMessage, isSquare } = this.props

    return (
      <div
        className={classNames('settings-form-input settings-form-image', {
          'settings-form-image-square': isSquare,
        })}
      >
        <ImageInput {...this.props} isSettingsFormField />
        {helpMessage ? (
          <p className="settings-form-help-message">{helpMessage}</p>
        ) : null}
      </div>
    )
  }

  renderFileInput() {
    const { helpMessage } = this.props

    return (
      <div className="settings-form-input settings-form-file">
        <FileInput {...this.props} />
        {helpMessage ? (
          <p className="settings-form-help-message">{helpMessage}</p>
        ) : null}
      </div>
    )
  }

  render() {
    const {
      label,
      input,
      placeholder,
      type,
      meta,
      skipTouch,
      inline,
      children,
      small,
      tooltip,
      ...props
    } = this.props

    const { error, touched } = meta

    const showError = skipTouch ? error : touched && error

    return (
      <>
        <div
          className={classNames('settings-form-row', {
            'settings-form-row-error': showError,
            'settings-form-row-inline': inline && children,
            'settings-form-row-small': small,
          })}
        >
          <div className="settings-form-label-wrapper">
            <label>{label}</label>
            {tooltip && (
              <Tooltip tooltip={tooltip} placement="bottom-start" hideArrow>
                <Icon type="help" />
              </Tooltip>
            )}
          </div>
          {type === 'image' ? (
            <>
              {this.renderImageInput()}
              {children}
            </>
          ) : type === 'file' ? (
            this.renderFileInput()
          ) : (
            <div className="settings-form-input">
              <input
                {...props}
                {...input}
                placeholder={placeholder}
                type={type}
              />
              {inline && children ? children : null}
            </div>
          )}
          {showError && <div className="settings-form-error">{error}</div>}
        </div>
      </>
    )
  }
}

export class SettingsFormSubmit extends Component {
  render() {
    let { children, title, enabled, className } = this.props

    title = title || 'Save'
    className = classNames('settings-form-submit', className)

    return (
      <div className={className}>
        {children}
        <Button disabled={!enabled}>{title}</Button>
      </div>
    )
  }
}
