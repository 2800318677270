import React from 'react'
import { Link } from 'react-router-dom'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import { useTranslation } from 'react-i18next'
import QS from 'qs'
import { PASSWORD_LENGTH_MAX } from '@adalo/constants'

import OnboardingLayout from 'components/Onboarding/Layout'
import Button from 'components/Shared/Button'
import WrappedInput from 'components/Shared/Forms/WrappedInput'
import AdaloLogotype from 'components/Onboarding/AdaloLogotype'
import { FormRow } from './utils'

const FORM_NAME = 'loginForm'

type Props = {
  submitting?: boolean
  error?: string
  redirect?: string
  handleSubmit: () => void
}

type LoginFormValues = {
  email: string
  password: string
}

type LoginFormFC = React.FC<Props & InjectedFormProps<LoginFormValues, Props>>

const LoginForm: LoginFormFC = props => {
  const { handleSubmit, error, submitting, redirect } = props
  const { t } = useTranslation()
  const redirectQuery = QS.stringify({ redirect }, { addQueryPrefix: true })

  return (
    <OnboardingLayout>
      <form onSubmit={handleSubmit} className="auth-form-v2">
        <div className="auth-form-v2__header">
          <AdaloLogotype />
          <h1>{t('Welcome Back!')}</h1>
        </div>
        <div className="auth-form-v2__content">
          <FormRow>
            <Field
              autoFocus
              name="email"
              placeholder={t('Email Address')}
              type="email"
              label={t('Email Address')}
              maxLength={255}
              autoComplete="email"
              component={WrappedInput}
            />
          </FormRow>
          <FormRow>
            <Field
              name="password"
              placeholder="••••••••"
              type="password"
              label={t('Password')}
              maxLength={PASSWORD_LENGTH_MAX}
              autoComplete="current-password"
              component={WrappedInput}
            />
          </FormRow>
          <div className="auth-form-submission-row">
            <Button square gradient large teal fluid loading={submitting}>
              {t('Sign In')}
            </Button>
          </div>

          {error && <div className="auth-form-error">{error}</div>}
          <div className="auth-form-link">
            <Link to="/forgot-password" className="link-button">
              {t('Forgot Password?')}
            </Link>
          </div>
        </div>
        <div className="auth-form-v2__footer">
          <div className="auth-form-link-v2">
            {t("Don't have an account?")}
            <Link to={`/signup${redirectQuery}`} className="link-button">
              {t('Sign Up')}
            </Link>
          </div>
        </div>
      </form>
    </OnboardingLayout>
  )
}

export default reduxForm<LoginFormValues, Props>({
  form: FORM_NAME,
})(LoginForm)
