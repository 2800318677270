import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getConnected } from '../../../ducks/sockets'
import BodyClass from '../../Shared/BodyClass'

import './OfflineBanner.css'
import { getApp } from '../../../ducks/apps'
import { getCurrentAppId } from '../../../ducks/editor/objects'

class OfflineBanner extends Component {
  render() {
    const { connected, bodyVersion } = this.props

    return (
      <>
        {connected ? null : <BodyClass className="offline-banner-visible" />}
        <div className="offline-banner">
          We're having difficulties connecting to our servers. Visit our{'  '}
          <a href="https://status.adalo.com" target="_blank" rel="noreferrer">
            status page
          </a>
          {'  '}
          and check your internet connection.{'  '}
          {bodyVersion ? `Your application version is ${bodyVersion}.` : null}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  const app = getApp(state, getCurrentAppId(state))
  const bodyVersion = app ? app.version || 0 : 0

  return { connected: getConnected(state), bodyVersion }
}

export default connect(mapStateToProps)(OfflineBanner)
