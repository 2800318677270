import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import QS from 'qs'
import { toast, cssTransition } from 'react-toastify'

import { getAuthVisible, setCurrentUserToken } from 'ducks/users/index.ts'

import { magicAuthenticate } from 'utils/io'
import { getMakerUserId, logoutMaker, parseJWT } from 'utils/auth'

import './styles.scss'
import i18next from 'i18next'

const MISSING_TOKEN = 'MISSING_TOKEN'

const AuthRedirect = props => {
  const { authVisible, setCurrentUserToken } = props

  const [redirect, setRedirect] = useState(null)

  const handleMagicLogin = useCallback(async () => {
    const { sessionToken: magicToken, ...params } = QS.parse(
      window.location.search,
      {
        ignoreQueryPrefix: true,
      }
    )

    const queryString = QS.stringify(params, { addQueryPrefix: true })

    if (magicToken) {
      const userId = getMakerUserId()
      const parsedMagicToken = parseJWT(magicToken)

      if (userId && parsedMagicToken) {
        const { userId: tokenUserId } = parsedMagicToken

        if (tokenUserId === userId) {
          window.history.replaceState(
            {},
            document.title,
            `${window.location.pathname}${queryString}`
          )

          return null
        }

        logoutMaker()
      }

      try {
        const sessionToken = await magicAuthenticate({ magicToken })

        window.history.replaceState(
          {},
          document.title,
          `${window.location.pathname}${queryString}`
        )

        return setCurrentUserToken(sessionToken)
      } catch (err) {
        localStorage.setItem('showTokenExpiredToast', true)
        throw new Error(err)
      }
    }

    throw new Error(MISSING_TOKEN)
  }, [window.location.search])

  handleMagicLogin().catch(() => {
    const alreadyAuthing = window.location.pathname.match(
      /^(\/[A-z]{2})\/(login)|(signup)|(accept-invite)|(forgot-password)|(recover-password)/
    )

    if (authVisible && !alreadyAuthing) {
      const query = QS.parse(window.location.search, {
        ignoreQueryPrefix: true,
      })

      let { login_type: loginType, sessionToken, ...params } = query
      const queryString = QS.stringify(params, { addQueryPrefix: true })

      const redirect = `${
        sessionToken ? '/' : window.location.pathname
      }${queryString}`

      const lang = i18next.language

      if (!['login', 'signup'].includes(loginType)) {
        loginType = 'login'
      }

      const url = `/${lang}/${loginType}?${QS.stringify({ redirect })}`

      return setRedirect(url)
    }
  })

  if (redirect) {
    if (localStorage.getItem('showTokenExpiredToast')) {
      localStorage.removeItem('showTokenExpiredToast')

      toast(
        <div>
          <p>That login link has expired, please log in again.</p>
        </div>,
        {
          position: 'bottom-right',
          className: 'token-expired__toast',
          bodyClassName: 'token-expired__toast-body',
          hideProgressBar: true,
          autoClose: 5000,
          transition: cssTransition({
            enter: 'animate__animated animate__fadeInUp',
            exit: 'animate__animated animate__fadeOutDown',
            collapse: false,
          }),
        }
      )
    }

    return <Redirect to={redirect} />
  }

  return null
}

const mapStateToProps = state => ({
  authVisible: getAuthVisible(state),
})

export default withRouter(
  connect(mapStateToProps, {
    setCurrentUserToken,
  })(AuthRedirect)
)
