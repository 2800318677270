import React, { useEffect, useState } from 'react'

import Stat from 'components/Shared/Stat/index.tsx'

import { getOrganizationBilling } from 'ducks/organizations'

import {
  getActionsSpendingLimit,
  getLimit,
  hasBusinessPlan,
} from 'utils/billing.ts'
import { scrollToHash } from 'utils/dom'

import { generateUsageStats } from './generateStats.ts'

import './UsageStats.scss'

const UsageStats = props => {
  const [spendingLimit, setSpendingLimit] = useState(null)

  useEffect(() => {
    scrollToHash()
  }, [])

  const { organization, pricingPlans } = props

  useEffect(() => {
    ;(async () => {
      const billing = await getOrganizationBilling(organization.id)

      const actionsSpendingLimit = getActionsSpendingLimit(
        billing?.spendingLimit,
        billing?.overageChargePlanType
      )

      setSpendingLimit(actionsSpendingLimit)
    })()
  }, [organization, spendingLimit])

  const { planType } = organization

  const { count: pubCount, limit: pubLimit } =
    organization?.addons?.publishedApps || {}

  const testCount = organization?.testAppsCount || 0
  const testLimit = 'unlimited'

  const seatCount =
    organization?.addons?.seats?.count || organization?.Users?.length || 0

  const seatLimit =
    organization?.addons?.seats?.limit ||
    getLimit(planType, pricingPlans, 'seats')

  const seatLimitText = !hasBusinessPlan(planType) ? seatLimit : 'unlimited'

  if (
    Number.isNaN(parseFloat(pubCount)) ||
    Number.isNaN(parseFloat(pubLimit))
  ) {
    return null
  }

  const usageStats = generateUsageStats(
    pubCount,
    pubLimit,
    testCount,
    testLimit,
    seatCount,
    seatLimitText
  )

  return (
    <div className="usage-stats">
      {usageStats.map(statInfo => (
        <Stat key={statInfo.title} {...statInfo} />
      ))}
    </div>
  )
}

export default UsageStats
