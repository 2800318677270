import { validators, PASSWORD_LENGTH_MAX } from '@adalo/constants'
import { instance } from '../../i18n'

export const validateSignUpForm = data => {
  const errors = {}

  if (!data.email) {
    errors.email = instance.t('Email is required')
  } else if (!data.email.match(validators.email)) {
    errors.email = instance.t('Please enter a valid email address')
  }

  if (!data.name) {
    errors.name = instance.t('Name is required')
  }

  if (data.name && !data.name.match(validators.name)) {
    errors.name = instance.t('Name has unsupported characters')
  }

  if (!data.password || data.password.length < 8) {
    errors.password = instance.t('Password must be at least 8 characters')
  }

  if (data.password && data.password.length > PASSWORD_LENGTH_MAX) {
    errors.password = instance.t('Password is too long')
  }

  if (
    data.password &&
    data.password.length >= 8 &&
    !data.password.match(validators.password)
  ) {
    errors.password = instance.t('Password has unsupported characters')
  }

  if (!data.terms) {
    errors.terms = instance.t('You must accept the Terms of Use')
  }

  return errors
}

export const validateEmailForm = (values, props) => {
  const { currentUser } = props
  const { emailValidation } = values
  const errors = {}

  if (!emailValidation) {
    errors.emailValidation = instance.t('Validation code is required')
  }

  if (
    emailValidation &&
    emailValidation.length !== 6 &&
    !typeof emailValidation === 'string'
  ) {
    errors.emailValidation = instance.t(
      'Validation code has unsupported characters'
    )
  }

  if (!currentUser) {
    errors.emailValidation = instance.t('User not found')
  }

  if (currentUser && emailValidation !== currentUser.emailValidationString) {
    errors.emailValidation = instance.t('Incorrect validation code')
  }

  return errors
}
