import React from 'react'
import { withRouter } from 'react-router-dom'

import { GroupedAccordion } from 'components/Shared/Accordion'
import Icon from 'components/Shared/Icon'
import Tooltip from 'components/Shared/Tooltip'
import DeepLinkingComponent from './DeepLinkingComponent.tsx'

import './DeepLinking.scss'

const DeepLinking = ({ appId }) => {
  return (
    <div className="app-versions-container">
      <GroupedAccordion
        defaultExpanded
        title={
          <div className="versions-accordion-title-inner">
            Deep Linking Settings
          </div>
        }
        className="versions-accordion"
        renderChildren={() => <DeepLinkingComponent appId={appId} />}
      />
    </div>
  )
}

const DeepLinkingPanel = ({ appId }) => {
  const tooltip = `Deep linking allows you to link directly to specific screens within your app.`

  return (
    <div className="versions-wrapper">
      <div className="left-panel-header">
        <h2 className="left-panel-title">
          <Icon type="link" />
          Deep Linking
        </h2>
        <Tooltip small hideArrow placement="bottom-start" tooltip={tooltip}>
          <Icon type="help-small" />
        </Tooltip>
      </div>

      <DeepLinking appId={appId} />
    </div>
  )
}

export default withRouter(DeepLinkingPanel)
