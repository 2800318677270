import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { getApp, updateApp } from 'ducks/apps'
import { createCustomAction, fetchCustomActions } from 'ducks/customActions'
import { openAccordion } from 'ducks/accordions'

import Modal from 'components/Shared/Modal'
import Button from 'components/Shared/Button'

import history from '../../../history'

import Intro from './Intro'
import LoginSetupForm from './LoginSetupForm'
import SignupSetupForm from './SignupSetupForm'
import ExternalCollectionSetupForm from './ExternalCollectionSetupForm'
import Summary from './Summary'

class ExternalUsersModal extends Component {
  state = { step: 0, formStep: 0, deleteWarning: false, nextEnabled: true }

  componentDidMount() {
    this.formRefs = []
    this.formRefs[1] = React.createRef()
    this.formRefs[2] = React.createRef()
  }

  handleClose = () => {
    const { match } = this.props
    const { appId } = match.params

    const url = `/apps/${appId}/screens`

    history.push(url)
  }

  handleSubmit = async values => {
    const { step } = this.state

    const { createCustomAction, appId, app, updateApp, fetchCustomActions } =
      this.props

    let customActionId

    switch (step) {
      case 1:
        values.name = 'External Login'
        values.type = 'External Users'
        values.appId = appId

        try {
          if (app.externalUsers.login.customActionId) {
            values.id = app.externalUsers.login.customActionId
          }

          customActionId = (await createCustomAction(appId, values))?.value?.id
          await fetchCustomActions(appId)
          app.externalUsers.login.formValues = values
          app.externalUsers.login.customActionId = customActionId
          app.externalUsers.login.authToken = values.authTokenField
          app.externalUsers.login.id = values.idField
          await updateApp(appId, app)
        } catch (err) {
          console.error('ERROR CREATING LOGIN ACTION:', err)
        }

        break
      case 2:
        values.name = 'External Signup'
        values.type = 'External Users'
        values.appId = appId

        try {
          if (app.externalUsers.signup.customActionId) {
            values.id = app.externalUsers.signup.customActionId
          }

          customActionId = (await createCustomAction(appId, values))?.value?.id
          await fetchCustomActions(appId)
          app.externalUsers.signup.formValues = values
          app.externalUsers.signup.customActionId = customActionId
          app.externalUsers.signup.authToken = values.authTokenField
          app.externalUsers.signup.id = values.idField
          await updateApp(appId, app)
        } catch (err) {
          console.error('ERROR CREATING LOGIN ACTION:', err)
        }

        break
      default:
    }
  }

  handleBack = () => {
    const { step, formStep, deleteWarning } = this.state

    if (deleteWarning) return this.setState({ deleteWarning: false })

    if (formStep === 0) {
      if (step !== 0) {
        return this.setState({ step: step - 1 })
      } else {
        return this.handleClose()
      }
    } else {
      return this.setState({ formStep: formStep - 1 })
    }
  }

  handleNext = e => {
    if (e?.preventDefault) e.preventDefault()
    const { step, formStep } = this.state

    if (step === FORM_STRUCTURE.length - 1) {
      this.handleClose()
    } else if (formStep === 0 && !FORM_STRUCTURE[step].children) {
      this.setState({ step: Math.min(step + 1, FORM_STRUCTURE.length - 1) })
    } else if (
      formStep &&
      formStep === FORM_STRUCTURE[step]?.children.length - 1
    ) {
      this.formRefs[step].current.ref.current.submit()

      this.setState({
        formStep: 0,
        step: Math.min(step + 1, FORM_STRUCTURE.length - 1),
      })
    } else {
      this.setState({ formStep: formStep + 1 })
    }
  }

  updateEnabled = bool => {
    this.setState({ nextEnabled: bool })
  }

  renderContent = () => {
    const { step, formStep } = this.state
    const { onCancel, app } = this.props

    const loginValues = app && app.externalUsers.login?.formValues

    const signupValues = app && app.externalUsers.signup?.formValues

    const collectionValues = app && app.externalUsers.collection?.formValues

    switch (step) {
      case 0:
        return (
          <Intro
            handleBack={this.handleBack}
            handleNext={this.handleNext}
            step={formStep}
            onCancel={onCancel}
          />
        )
      case 1:
        return (
          <LoginSetupForm
            handleBack={this.handleBack}
            handleNext={this.handleNext}
            step={formStep}
            onCancel={onCancel}
            ref={this.formRefs[step]}
            updateEnabled={this.updateEnabled}
            onSubmit={this.handleSubmit}
            initialValues={loginValues}
          />
        )
      case 2:
        return (
          <SignupSetupForm
            handleBack={this.handleBack}
            handleNext={this.handleNext}
            step={formStep}
            onCancel={onCancel}
            ref={this.formRefs[step]}
            updateEnabled={this.updateEnabled}
            onSubmit={this.handleSubmit}
            initialValues={signupValues}
          />
        )
      case 3:
        return (
          <ExternalCollectionSetupForm
            handleBack={this.handleBack}
            handleNext={this.handleNext}
            step={formStep}
            onCancel={onCancel}
            ref={this.formRefs[step]}
            updateEnabled={this.updateEnabled}
            onSubmit={this.handleSubmit}
            initialValues={collectionValues}
          />
        )
      case 4:
        return (
          <Summary
            handleBack={this.handleBack}
            handleNext={this.handleNext}
            step={formStep}
            onCancel={onCancel}
          />
        )
    }
  }

  render() {
    const { step, nextEnabled } = this.state

    return (
      <Modal size="lg" scrolling>
        <div>
          <Modal.Header
            stepper
            color="red"
            steps={[
              'Getting Started',
              'Login Setup',
              'Signup Setup',
              'Users Collection',
              'Summary',
            ]}
            currentStep={step}
          />
          <Modal.Content>{this.renderContent()}</Modal.Content>
          <Modal.Actions>
            <Button text onClick={this.handleClose} type="button">
              Save and Finish Later
            </Button>
            <Button text onClick={this.handleBack} type="button">
              Back
            </Button>
            <Button
              red
              type="submit"
              disabled={!nextEnabled}
              onClick={this.handleNext}
            >
              {step === 4 ? 'Done' : 'Next'}
            </Button>
          </Modal.Actions>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state, { match }) => {
  return {
    appId: match.params.appId,
    app: getApp(state, match.params.appId),
  }
}

export default withRouter(
  connect(mapStateToProps, {
    createCustomAction,
    openAccordion,
    updateApp,
    fetchCustomActions,
  })(ExternalUsersModal)
)

export const FORM_STRUCTURE = [
  {
    name: 'intro',
    title: 'Getting Started',
  },
  {
    name: 'login',
    title: 'Login',
    children: [
      {
        name: 'setup',
        title: 'Setup',
      },
      {
        name: 'outputs',
        title: 'Outputs',
      },
    ],
  },
  {
    name: 'login',
    title: 'Login',
    children: [
      {
        name: 'setup',
        title: 'Setup',
      },
      {
        name: 'outputs',
        title: 'Outputs',
      },
    ],
  },
  {
    name: 'collection',
    title: 'Users Collection',
    children: [
      {
        name: 'setup',
        title: 'Setup',
      },
      {
        name: 'properties',
        title: 'Properties',
      },
    ],
  },
  {
    name: 'summary',
    title: 'Status',
  },
]
