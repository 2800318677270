import React from 'react'
import { connect, useDispatch } from 'react-redux'
import classNames from 'classnames'
import { GROUP, LIST } from '@adalo/constants'
import { withRouter, Switch, Route, useHistory } from 'react-router-dom'

import {
  ADD,
  ANALYTICS,
  BRANDING,
  DATA,
  DEEP_LINKING,
  EXPERTS,
  PUBLISH,
  SCREENS,
  SETTINGS,
  TABS,
  VERSIONS,
} from 'utils/tabs'

import { getSelection, setSelection } from 'ducks/editor/selection'
import { getParentComponent } from 'ducks/editor/objects'
import { getDefaultDatasource } from 'ducks/apps/datasources'
import { FEATURE_TEMPLATE_MODAL, showModal } from 'ducks/editor/modals'
import { getApp } from 'ducks/apps'

import DataPanel from 'components/AppDetail/Data/Panel'
import { SettingsPanel } from 'components/AppDetail/Settings'
import MagicStartPanel from 'components/AppDetail/Data/DBAssistant/MagicStart'
import MagicAddPanel from 'components/AppDetail/Data/DBAssistant/MagicAdd'
import { Panel } from 'components/Shared/Panel'

import AddPanel from 'components/Editor/AddObject/Panel'
import EditPanel from 'components/Editor/RightPanel'
import PublishPanel from 'components/Editor/Publish'
import BrandingPanel from 'components/Editor/Branding'
import ScreensPanel from 'components/Editor/Screens'
import AnalyticsPanel from 'components/Editor/Analytics'
import VersionsPanel from 'components/Editor/Versions'
import ExpertsPanel from 'components/Editor/Experts'
import FeatureTemplatesCTA from 'components/Editor/FeatureTemplateModal/CallToAction'

import MarketplaceCallToAction from './MarketplaceCTA'
import DeepLinkingPanel from '../DeepLinking'

import './LeftPanel.scss'

const secondaryTabs = ['section', 'feature', 'search']

const LeftPanel = ({
  match,
  location,
  selection,
  magicLayout,
  isXanoApp,
  app,
}) => {
  const { appId } = match.params

  const dispatch = useDispatch()
  const history = useHistory()

  const showFeatureTemplates = magicLayout

  const { pathname } = location
  const activeTab = TABS.find(config => {
    return pathname.includes(config.path)
  })

  const editorHasSelection = selection.length > 0
  const hasActiveTab = !!activeTab

  const renderFooter = () => {
    if (activeTab !== ADD || !showFeatureTemplates || editorHasSelection) {
      return null
    }

    const openTab = secondaryTabs.find(tab => pathname.includes(tab))

    if (openTab === 'search' || openTab === 'section') {
      return null
    }

    if (openTab === 'feature') {
      return (
        <FeatureTemplatesCTA
          appId={appId}
          onClick={() => dispatch(showModal(FEATURE_TEMPLATE_MODAL, { appId }))}
        />
      )
    }

    return (
      <MarketplaceCallToAction
        appId={appId}
        onClick={() => history.push(`/apps/${appId}/marketplace`)}
      />
    )
  }

  const renderSub = () => {
    if (editorHasSelection) return <EditPanel magicLayout={magicLayout} />

    if (!hasActiveTab) return null

    return (
      <Switch>
        <Route
          path={`/apps/:appId/${ADD.path}`}
          render={props => (
            <AddPanel
              {...props}
              magicLayout={magicLayout}
              isXanoApp={isXanoApp}
              app={app}
            />
          )}
        />
        <Route
          path={`/apps/:appId/${BRANDING.path}`}
          component={BrandingPanel}
        />
        <Route path={`/apps/:appId/${DATA.path}`} exact component={DataPanel} />
        <Route
          exact
          path={`/apps/:appId/${DATA.path}/magic-start`}
          component={MagicStartPanel}
        />
        <Route
          exact
          path={`/apps/:appId/${DATA.path}/magic-add`}
          component={MagicAddPanel}
        />
        <Route path={`/apps/:appId/${PUBLISH.path}`} component={PublishPanel} />
        <Route
          path={`/apps/:appId/${SCREENS.path}`}
          render={props => <ScreensPanel {...props} appId={appId} />}
        />
        <Route
          path={`/apps/:appId/${SETTINGS.path}`}
          component={SettingsPanel}
        />
        <Route
          path={`/apps/:appId/${DEEP_LINKING.path}`}
          render={props => <DeepLinkingPanel {...props} appId={appId} />}
        />
        <Route
          path={`/apps/:appId/${ANALYTICS.path}`}
          render={props => <AnalyticsPanel {...props} appId={appId} />}
        />
        <Route
          path={`/apps/:appId/${VERSIONS.path}`}
          render={props => <VersionsPanel {...props} appId={appId} />}
        />
        <Route
          path={`/apps/:appId/${EXPERTS.path}`}
          render={props => <ExpertsPanel {...props} appId={appId} />}
        />
      </Switch>
    )
  }

  return (
    <Panel
      className={classNames('edit-bar', {
        'edit-bar-empty': !editorHasSelection && !hasActiveTab,
        'edit-bar-add': activeTab === ADD,
        'edit-bar-experts': activeTab === EXPERTS,
        'edit-bar-analytics': activeTab === ANALYTICS,
      })}
      footer={renderFooter()}
    >
      {renderSub()}
    </Panel>
  )
}

const mapStateToProps = (state, { match }) => {
  const { appId } = match.params
  const selection = getSelection(state)

  const app = getApp(state, appId)
  const isXanoApp =
    app?.externalUsers?.enabled && app?.externalUsers?.provider === 'xano'

  const parentComponent =
    selection.length === 1 ? getParentComponent(state, selection[0]) : null

  return {
    hasDoneEditing:
      parentComponent &&
      (parentComponent.type === GROUP || parentComponent.type === LIST),
    parentComponentId: parentComponent && parentComponent.id,
    selection,
    datasource: getDefaultDatasource(state, appId),
    isXanoApp,
    app,
  }
}

const mapDispatchToProps = {
  setSelection,
}

const connected = connect(mapStateToProps, mapDispatchToProps)(LeftPanel)

export default withRouter(connected)
