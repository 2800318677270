import EmptyState from 'components/Shared/EmptyState'
import { useEffect, useState } from 'react'
import imgEmptyState from '../Canvas/Empty/empty-state.png'
import './NoDatabaseBlocker.scss'
import { wakeUpDatasource } from '../../../utils/io'
import Button from '../../Shared/Button'
import { HIBERNATION_STATUS } from '../../../ducks/datasources'

interface NoDatabaseBlockerProps {
  appId: string
  datasourceId: string
  hibernationStatus: string
}

const NoDatabaseBlocker = (
  props: NoDatabaseBlockerProps
): JSX.Element | null => {
  const { hibernationStatus, datasourceId, appId } = props
  const [currentStatus, setCurrentStatus] = useState<string>(hibernationStatus)

  const handleAwake = async () => {
    setCurrentStatus(HIBERNATION_STATUS.WAKING_UP)
    await wakeUpDatasource(datasourceId, appId)
  }

  useEffect(() => {
    if (hibernationStatus === HIBERNATION_STATUS.HIBERNATED) {
      handleAwake().catch(console.error)
    }
  }, [hibernationStatus])

  const getStatusMessage = (status: string): [string, boolean] => {
    switch (status) {
      case HIBERNATION_STATUS.HIBERNATED:
        return [
          'Your database is currently in a hibernated state due to inactivity. Please try waking it up to regain access.',
          false,
        ]
      case HIBERNATION_STATUS.WAITING_AWAKE:
        return [
          'The datasource is currently in a waiting state, preparing to wake up. Please reload the page in 1 minute.',
          true,
        ]
      case HIBERNATION_STATUS.WAKING_UP:
        return [
          'The datasource is in the process of waking up. Please reload the page in 1 minute.',
          true,
        ]
      case HIBERNATION_STATUS.WAKEUP_FAILED:
        return [
          'The attempt to wake up the datasource has failed. Please contact our support team, for the assistance.',
          true,
        ]
      default:
        return [
          'Unfortunately, your database is currently unavailable. Please contact our support team or consider creating a new application, for assistance.',
          false,
        ]
    }
  }

  const [statusMessage] = getStatusMessage(currentStatus)

  return (
    <>
      <div className="blocked-wrapper">
        <EmptyState greedy className="blocked-content">
          <img
            src={imgEmptyState}
            alt="browser and mobile screen"
            height={220}
          />
          <h1>Database Unavailable</h1>
          <p>{statusMessage}</p>
          {currentStatus === HIBERNATION_STATUS.WAKING_UP && (
            <Button
              text
              outlined
              teal
              onClick={() => window.location.reload()}
              type="button"
            >
              Reload
            </Button>
          )}
        </EmptyState>
      </div>
    </>
  )
}

export default NoDatabaseBlocker
