import { ForwardedRef, forwardRef, useContext } from 'react'
import styled from 'styled-components'
import TableThemeContext from './TableThemeContext'
import TableRowSeparator from './TableRowSeparator'
import { MIN_PAGINATION_HEIGHT } from './layout'

import { ReactComponent as PaginationNext } from './pagination-next.svg'
import { ReactComponent as PaginationPrevious } from './pagination-previous.svg'

const Container = styled('div')<{
  $borderRadius: number
  $borderWidth: number
  $backgroundColor: string
  $height: number
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: ${props => props.$height}px;
  position: absolute;
  bottom: 0;
  background-color: ${props => props.$backgroundColor};
  border-bottom-left-radius: ${props =>
    props.$borderRadius - props.$borderWidth}px;
  border-bottom-right-radius: ${props =>
    props.$borderRadius - props.$borderWidth}px;
`

export const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 12px;
  width: 56px;
`

const Pagination = (
  _: unknown,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element | null => {
  const { tableRowFontColor, borderWidth, borderRadius, backgroundColor } =
    useContext(TableThemeContext)

  const paginationProps = {
    width: 20,
    height: 20,
    fill: tableRowFontColor,
  }

  return (
    <Container
      $borderRadius={borderRadius}
      $borderWidth={borderWidth}
      $backgroundColor={backgroundColor}
      $height={MIN_PAGINATION_HEIGHT}
      ref={ref}
    >
      <TableRowSeparator position="top" />
      <ButtonContainer>
        <PaginationPrevious {...paginationProps} style={{ opacity: 0.2 }} />
        <PaginationNext {...paginationProps} />
      </ButtonContainer>
    </Container>
  )
}

export default forwardRef(Pagination)
