// TODO(dyego): convert it to TypeScript
import React, { useMemo, useState, useEffect, useReducer } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import { formValueSelector, reduxForm } from 'redux-form'

import { adaloBackendAxios } from 'utils/io/http/axios'

import { getApp } from 'ducks/apps'
import { createTable, getDatasources } from 'ducks/apps/datasources'

import Modal from 'components/Shared/Modal'
import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'

import history from '../../../history'

import xanoAdaloSvg from './assets/xano-adalo.svg'

import ExternalUsersForm from './ExternalUsersForm'
import ExternalCollectionsForm from './ExternalCollectionsForm'
import Banner from './Banner.tsx'
import { GlobalXanoContextProvider } from './context'
import { updateCollectionDatasource } from './lib/collection'
import {
  XANO_INTEGRATION_HELP_DOCS_URL,
  XANO_LEARN_MORE_URL,
} from '../../../constants'

// TODO @danicunhac - move part of styling to ExternalUsersForm specific file
import './index.scss'

export const FORM_NAME = 'externalUsersSetup'
const NONE = 'none'
const LOADING = 'loading'
const SAVING = 'saving'
const SUCCESS = 'success'
const USER_SETUP_COMPLETED = 'userSetupCompleted'
const FAILURE = 'failure'

const useCollections = ({ url, appId, step }) => {
  const [collections, updateCollections] = useReducer(
    (state, action) => {
      return { ...state, ...action }
    },
    { status: 'idle', spec: {}, tables: {}, error: undefined }
  )

  const fetchCollections = async (url, appId) => {
    updateCollections({ status: 'loading', error: undefined })

    try {
      const { data } = await adaloBackendAxios.get(
        `/external-databases/xano/external-collections`,
        { params: { url, appId } }
      )

      updateCollections({ status: 'loaded', ...data })
    } catch (error) {
      updateCollections({ status: 'error', error })
    }
  }

  useEffect(() => {
    if (
      step === 2 &&
      typeof url === 'string' &&
      collections.status === 'idle'
    ) {
      fetchCollections(url, appId)
    }
  }, [step, url, collections.status])

  return [collections, updateCollections]
}

const XanoExternalDatabaseModal = props => {
  const dispatch = useDispatch()
  const selector = formValueSelector(FORM_NAME)

  const { match } = props
  const app = useSelector(state => getApp(state, match.params.appId))
  const datasources = useSelector(state =>
    app ? getDatasources(state, app.id) : undefined
  )
  const datasource = datasources?.[0]

  const formValueCollections =
    useSelector(state => selector(state, 'collections')) || {}
  const apiURL = useSelector(state => selector(state, 'apiURL')) || {}

  const [step, setStep] = useState(0)
  const [formStep, setFormStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [deleteWarning, setDeleteWarning] = useState(false)
  const [nextEnabled, setNextEnabled] = useState(true)
  const [setupState, setSetupState] = useState(NONE)
  const [isEdit, setIsEdit] = useState(false)

  const [collections] = useCollections({ url: apiURL, appId: app.id, step })

  const setupComplete = app?.externalUsers?.setupStatus?.complete

  useEffect(() => {
    const {
      location: { search },
    } = props

    const step = search?.split('=')[1]

    if (step) {
      setStep(parseInt(step))
      setIsEdit(true)
    }
  }, [])

  const handleClose = () => {
    const { match } = props
    const { appId } = match.params
    const url = `/apps/${appId}/data`

    history.push(url)
  }

  const openHelpDocs = () => {
    window.open(XANO_INTEGRATION_HELP_DOCS_URL, '_blank', 'noreferrer')
  }

  const handleBack = () => {
    if (deleteWarning) return setDeleteWarning(false)

    if (step !== 0) {
      setStep(step - 1)

      if (step === 1) {
        setNextEnabled(true)
      }

      if (step === 1) {
        setSetupState(NONE)
      }

      if (formStep > 0) {
        setFormStep(formStep - 1)
      }
    } else {
      handleClose()
    }
  }

  const handleExternalCollections = async () => {
    setIsLoading(true)

    const selectedValues = Object.keys(formValueCollections).filter(
      collection => formValueCollections[collection]
    )

    try {
      if (selectedValues.length) {
        for (const [table, fields] of Object.entries(collections.tables)) {
          if (!selectedValues.includes(table)) continue
          const value = updateCollectionDatasource(
            datasource,
            table,
            collections.spec[table],
            fields,
            apiURL
          )

          value.updates.tableType = value.updates.type
          dispatch(createTable(app.id, datasource.id, value.updates))
        }
      }

      setIsLoading(false)
      setStep(3)
    } catch (err) {
      setIsLoading(false)
    }
  }

  const handleNext = e => {
    if (e?.preventDefault) e.preventDefault()

    if (step === 2) {
      return handleExternalCollections()
    }

    if (step === 3) {
      return handleClose()
    }

    if (step === 1) {
      if (setupState === NONE) {
        setSetupState(LOADING)
        setNextEnabled(false)
      } else if (setupState === USER_SETUP_COMPLETED) {
        const setSetupLabel = setupComplete ? SAVING : SUCCESS
        const nextEnabledLAbel = !setupComplete

        setSetupState(setSetupLabel)
        setNextEnabled(nextEnabledLAbel)
        setTimeout(() => {
          setSetupState(SUCCESS)

          if (isEdit) {
            handleClose()
          } else {
            setNextEnabled(true)
          }

          if (!setupComplete) {
            setTimeout(() => {
              setStep(step + 1)
            }, 1000)
          }
        }, 1000)
      } else if (setupState === FAILURE) {
        setSetupState(LOADING)
        setNextEnabled(false)
      } else if (setupState === SUCCESS) {
        setStep(step + 1)
      }

      return
    }

    setStep(step + 1)
  }

  const getStepLabel = () => {
    if (step === 1) {
      switch (setupState) {
        case NONE:
          return 'Run Test'
        case LOADING:
          return 'Loading...'
        case SAVING:
          return 'Saving...'
        case SUCCESS:
          return 'Next'
        case USER_SETUP_COMPLETED:
          if (setupComplete) {
            return 'Save'
          }

          return 'Next'
        case FAILURE:
          return 'Try Again'
        default:
          return 'Next'
      }
    }

    switch (step) {
      case 0:
        return 'Begin'
      case 2:
        return 'Finish'
      case 3:
        return 'Done'
      default:
        return 'Next'
    }
  }

  const renderContent = () => {
    const { onCancel } = props

    switch (step) {
      case 0:
        return (
          <Banner
            handleBack={handleBack}
            handleNext={handleNext}
            step={formStep}
            onCancel={onCancel}
          />
        )
      case 1:
        return (
          <ExternalUsersForm
            app={app}
            nextEnabled={nextEnabled}
            setNextEnabled={setNextEnabled}
            setupState={setupState}
            setSetupState={setSetupState}
          />
        )
      case 2:
        return (
          <ExternalCollectionsForm
            spec={collections.spec}
            status={collections.status}
            datasource={datasource}
          />
        )
      case 3:
        return (
          <Banner
            handleBack={handleBack}
            handleNext={handleNext}
            step={formStep}
            onCancel={onCancel}
            setupComplete
          />
        )
    }
  }

  const leftButtons = useMemo(() => {
    if (step === 0) {
      return [
        <Button
          className="xano-help-docs-button"
          red
          outlined
          onClick={openHelpDocs}
          type="button"
          key="help-docs"
        >
          HELP DOCS
          <Icon small type="open-in-new" />
        </Button>,
      ]
    }

    return (step === 1 || step === 2) && isEdit
      ? [
          <Button
            key="close"
            text
            onClick={handleClose}
            type="button"
            disabled={setupState === LOADING}
          >
            Close
          </Button>,
        ]
      : [
          <Button
            key="back"
            text
            onClick={handleBack}
            type="button"
            disabled={setupState === LOADING}
          >
            Back
          </Button>,
        ]
  }, [step, setupState])

  const setupStep = step === 1
  const showFinishLater = setupState === NONE && !setupComplete
  const stepLabel = getStepLabel()

  return (
    <Modal
      size={setupStep ? 'md' : 'sm'}
      scrolling
      className={classNames('external-users-modal', {
        colored: setupStep,
      })}
    >
      <GlobalXanoContextProvider>
        <div>
          {step === 1 || step === 2 ? (
            <Modal.Header>
              <div>
                <img
                  src={xanoAdaloSvg}
                  alt="External Users Welcome"
                  width="15%"
                />
                {step === 1 ? (
                  <h1>Xano Database Setup: Authentication & Users</h1>
                ) : (
                  <h1>Xano Database Setup: All Other Collections</h1>
                )}
              </div>
              <a href={XANO_LEARN_MORE_URL} target="_blank" rel="noreferrer">
                Learn More
              </a>
            </Modal.Header>
          ) : null}
          <Modal.Content>{renderContent()}</Modal.Content>
          <Modal.Actions leftButtons={leftButtons}>
            {showFinishLater ? (
              <Button text onClick={handleClose} type="button">
                Save and Finish Later
              </Button>
            ) : null}
            <Button
              red
              type="submit"
              disabled={!nextEnabled || isLoading}
              onClick={handleNext}
              loading={isLoading}
            >
              {stepLabel}
            </Button>
          </Modal.Actions>
        </div>
      </GlobalXanoContextProvider>
    </Modal>
  )
}
const XanoExternalDatabaseForm = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(XanoExternalDatabaseModal)

export default connect((state, { match }) => {
  const app = getApp(state, match.params.appId)

  const initialValues = app?.externalUsers?.formValues

  return {
    app,
    initialValues,
  }
})(XanoExternalDatabaseForm)
